import { Button, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
// import Calendar from "../../components/Calendar";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";

import Card from "../../components/Card";
import { ListTransactions } from "../../components/ListTransactions";
import { ResumoTransacoes } from "../../components/ResumoTransacoes";
import { getUser, logout } from "../../services/auth";
import api from "../../services/axios";
import 'react-big-calendar/lib/css/react-big-calendar.css'
import "./style.scss";
import { currency } from "../../intl";
const localizer = momentLocalizer(moment);
export function Dashboard() {
  const [transacoes, setTransacoes] = useState([]);
  const [myEvents, setMyEvents] = useState([]);
  const [transacoesVencemHoje, setTransacoesVencemHoje] = useState([]);
  const [transacoesVencemSemana, setTransacoesVencemSemana] = useState([]);
  const [transacoesVencidas, setTransacoesVencidas] = useState([]);
  const [totalDiaSelecionado, setTotalDiaSelecionado] = useState([]);
  const [transacoesVencidasClientes, setTransacoesVencidasClientes] = useState(
    []
  );
  const [transacoesVencidasEmprestimo, setTransacoesVencidasEmprestimo] =
    useState([]);
  const [transacoesAVencer, setTransacoesAVencer] = useState([]);
  const [mes, setMes] = useState(moment().format("YYYY-MM-DD"));
  useEffect(() => {
    let token = getUser().token;
    api.defaults.headers.authorization = `Bearer ${token}`;
    api
      .get("/transactions/list?data=" + mes)
      .then((response) => {
        setTransacoes(response.data);
        
      })
      .catch((err) => {
        console.log(err);
        if (err) {
          if (err.response.status === 401) {
            logout();
          }
        }
      });
    api
      .get("/transactions/list")
      .then((response) => {
        let NewmyEvents = []
        for(let i of response.data){
          NewmyEvents.push({
            title: `${currency(i.valor)} - ${i.clienteNome}`,
            start: moment(i.data),
            end: moment(i.data),
            allDay:true,
            resource:{id:i.lancamento}
          })
        }
        setMyEvents(NewmyEvents)
        let newTransacoesVencidas = [];
        let newTransacoesVencidasClientes = [];
        let clientes = [];
        let emprestimos = [];
        let newTransacoesVencidasEmprestimo = [];
        let newTransacoesAVencer = [];
        let newTransacoesVencemHoje = [];
        let newTransacoesVencemSemana = [];
        let a = 0;
        let b = 0;
        for (let i of response.data) {
          if (moment(i.data) < moment() && i.status === false) {
            newTransacoesVencidas.push(i);
            if (clientes.indexOf(i.cliente) === -1) {
              newTransacoesVencidasClientes.push(i);
              clientes.push(i.cliente);
            }
            if (emprestimos.indexOf(i.lancamento) === -1) {
              newTransacoesVencidasEmprestimo.push(i);
              clientes.push(i.lancamento);
            }
          }
          if (moment(i.data) > moment()) {
            newTransacoesAVencer.push(i);
          }
          if (
            moment(i.data).format("DD/MM/YYYY") ===
            moment().format("DD/MM/YYYY")
          ) {
            newTransacoesVencemHoje.push(i);
          }
          if (moment(i.data).format("ww") === moment().format("ww")) {
            newTransacoesVencemSemana.push(i);
          }
        }
        setTransacoesVencidas(newTransacoesVencidas);
        setTransacoesVencidasClientes(newTransacoesVencidasClientes);
        setTransacoesVencidasEmprestimo(newTransacoesVencidasEmprestimo);
        setTransacoesAVencer(newTransacoesAVencer);
        setTransacoesVencemHoje(newTransacoesVencemHoje);
        setTransacoesVencemSemana(newTransacoesVencemSemana);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          logout();
        }
      });
  }, [mes]);
  const viewsSelect = ['month','week','work_week','day','agenda']
  const [dateSelected, setDateSelected] = useState(moment()) 
  const [viewsSelected, setViewsSelected] = useState(viewsSelect[0]) 

  function handleMes(event) {
    setMes(event);
  }
  function calcularDiaParaReceber(data, viewS){
    api.get("/transactions/list?data=" + moment(data).format("YYYY-MM-DD"))
    .then((response) => {
      // console.log(response.data);
      let newTotal = 0
      let newType= ''
      for(let d of response.data){
        if(viewS === 'day'){
          newType='o dia ' + moment(data).format("DD/MM/YYYY")
          if(moment(data).format("DD/MM/YYYY") === moment(d.data).format("DD/MM/YYYY")){
            newTotal = newTotal+d.valor
          }
        }
        if(viewS === 'month'){
          newType='o mês de ' + moment(data).format("MMMM/YYYY")
          if(moment(data).format("MM/YYYY") === moment(d.data).format("MM/YYYY")){
            newTotal = newTotal+d.valor
          }
        }
        if(viewS === 'agenda'){
          newType='o mês de ' + moment(data).format("MMMM/YYYY") + ' depois do dia '+ moment(data).format("DD")
          console.log()
          if(moment(data) < moment(d.data)){
            newTotal = newTotal+d.valor
          }
        }
        if(viewS === 'week'){
          newType='a semana'
          if(moment(data).format("WW/YYYY") === moment(d.data).format("WW/YYYY")){
            newTotal = newTotal+d.valor
          }
        }
      }
      setTotalDiaSelecionado({valor:newTotal, type:newType})
    })
    .catch((err) => {
      console.log(err);
      if (err) {
        if (err.response.status === 401) {
          logout();
        }
      }
    });
  } 
  function dateClick(event){
    
    setDateSelected(moment(event.start))
    setViewsSelected(viewsSelect[3])
    // calcularDiaParaReceber(event.start, 'day')

  }
 
  function nextButton(){
    let duration
    let viewS
    if(viewsSelected === 'month' || viewsSelected === 'agenda'){
      duration = '720:00:00'
      viewS = 'month'
    }
    if(viewsSelected === 'day'){
      duration = '24:00:00'
      viewS = 'day'
    }
    if(viewsSelected === 'week'){
      duration = '168:00:00'
      viewS = 'week'
    }
    let dataCalculada = moment(moment(dateSelected)+moment.duration(duration))
    setDateSelected(dataCalculada)
    // calcularDiaParaReceber(dataCalculada.format, )
    setMes(moment(dataCalculada).format("YYYY-MM-DD"))
  }
  function prevButton(){
    let duration
    let viewS
    if(viewsSelected === 'month' || viewsSelected === 'agenda'){
      duration = '720:00:00'
      viewS = 'month'
    }
    if(viewsSelected === 'day'){
      duration = '24:00:00'
      viewS = 'day'
    }
    if(viewsSelected === 'week'){
      duration = '168:00:00'
      viewS = 'week'
    }
    let dataCalculada = moment(moment(dateSelected)-moment.duration(duration))
    setDateSelected(dataCalculada)
    // calcularDiaParaReceber(dataCalculada, viewS)
    setMes(moment(dataCalculada).format("YYYY-MM-DD"))
  }

  useEffect(()=>{
    calcularDiaParaReceber(dateSelected, viewsSelected)
  }, [viewsSelected, dateSelected])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <Card>
          <h3>Parcelas com vencimento hoje ({transacoesVencemHoje.length})</h3>
          <ListTransactions transacoes={transacoesVencemHoje} />
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card>
          <h3>
            Parcelas com vencimento nessa semana (
            {transacoesVencemSemana.length})
          </h3>
          <ListTransactions transacoes={transacoesVencemSemana} />
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card>
          <h3>
            Clientes com parcelas vencidas ({transacoesVencidasClientes.length})
          </h3>
          <ListTransactions transacoes={transacoesVencidasClientes} />
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card>
          <h3>
            Empréstimos com parcelas vencidas (
            {transacoesVencidasEmprestimo.length})
          </h3>
          <ListTransactions transacoes={transacoesVencidasEmprestimo} />
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card>
          <h3>Parcelas a vencer ({transacoesAVencer.length})</h3>
          <ListTransactions transacoes={transacoesAVencer} />
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card>
          <h3>Parcelas vencidas ({transacoesVencidas.length})</h3>
          <ListTransactions transacoes={transacoesVencidas} />
        </Card>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Card>
          <ResumoTransacoes transacoes={transacoes} dia={totalDiaSelecionado} />
        </Card>
      </Grid>
      <Grid item xs={12} sm={9}>
        <Card className="calendar">
          {/* <Calendar event setMes={(event) => handleMes(event)} /> */}
          <Grid className="calendarHeader">
              <Grid className="titleHeader">
                <Button className="arrowBtn" onClick={prevButton}>
                  <i className="fa fa-angle-left" />
                </Button>
                <Grid className="headerText">
                {viewsSelected === 'month' && dateSelected.format("MMMM - YYYY")}
                {viewsSelected === 'day' && dateSelected.format("DD - MMMM - YYYY") }
                {viewsSelected === 'week' && dateSelected.format("MMMM - YYYY")}
                {viewsSelected === 'agenda' && dateSelected.format("MMMM - YYYY")}
                </Grid>
                <Button className="arrowBtn" onClick={nextButton}>
                  <i className="fa fa-angle-right" />
                </Button>
              </Grid>
              {/* <DayNames /> */}
            </Grid>
            <Grid container style={{borderBottom:"1px solid #E6E6E6", justifyContent:"center"}} >
              {moment().format("DD/MM/YYYY")!==dateSelected.format("DD/MM/YYYY")&& <span style={{cursor:"pointer"}} onClick={()=>setDateSelected(moment())}>Voltar para hoje</span>}
            </Grid>
            <Grid container >
              <Grid onClick={()=>{setViewsSelected('month')}} item xs={3}  style={viewsSelected==='month'? {cursor:"pointer",padding:"10px",background: "#3174AD", color: "#fff"}:{cursor:"pointer",padding:"10px"}}>
                Mês
              </Grid>
              <Grid onClick={()=>{setViewsSelected('day')}} item xs={3} style={viewsSelected==='day'? {cursor:"pointer",padding:"10px",background: "#3174AD", color: "#fff"}:{cursor:"pointer",padding:"10px"}}>
                Dia
              </Grid>
              <Grid onClick={()=>{setViewsSelected('week')}} item xs={3} style={viewsSelected==='week'? {cursor:"pointer",padding:"10px",background: "#3174AD", color: "#fff"}:{cursor:"pointer",padding:"10px"}}>
                Semana
              </Grid>
              <Grid onClick={()=>{setViewsSelected('agenda')}} item xs={3} style={viewsSelected==='agenda'? {cursor:"pointer",padding:"10px",background: "#3174AD", color: "#fff"}:{cursor:"pointer",padding:"10px"}}>
                Agenda
              </Grid>
            </Grid>
            
          <Calendar
            view={viewsSelected}
            localizer={localizer}
            events={myEvents}
            startAccessor="start"
            endAccessor="end"
            resizable
            style={{ height: "640px" }}
            popup
            selectable
            toolbar={false}
            date={dateSelected}
            onSelectSlot={(slotInfo)=>{dateClick(slotInfo)}}
            onDrillDown={(slotInfo)=>{dateClick({start:slotInfo})}}
            onSelectEvent={(slotInfo)=>{dateClick(slotInfo)}}
            onView={(event)=>setViewsSelected(event)}
            onDoubleClickEvent={(event)=>window.location.href=`/lancamento/${event.resource.id}`}
            onClickEvent={(event)=>console.log(event)}
            onRangeChange={(event)=>console.log(event)}
            onNavigate={(event)=>console.log(event)}
          />
        </Card>
      </Grid>
    </Grid>
  );
}
