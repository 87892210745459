import React from 'react';
import Routes from './__Routes';

import { Helmet } from 'react-helmet';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
// import { ToastContainer, toast } from 'react-toastify';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';




// import 'react-toastify/dist/ReactToastify.min.css';
import GlobalStyle from './global-styles';
import MomentUtils from '@date-io/moment';

import './style.scss';

const theme = createMuiTheme();

const App = (props:any) => (
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={MomentUtils} locale={"pt-Br"}>
    <GlobalStyle />
    <Helmet defaultTitle="factoryadmin" />
    <Routes />
    </MuiPickersUtilsProvider>

    {/* <ToastContainer position="top-center" /> */}
  </MuiThemeProvider>
);
export default App;
