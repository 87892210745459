import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { Grid, Button, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Card from "../../components/Card/";

import { currency, dateBr } from "../../intl";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { MdSchedule } from "react-icons/md";
import DefaultModal from "../../components/modal";

import api from "../../services/axios.ts";
import { getUser } from "../../services/auth.ts";
import { ResumoTransacoes } from "../../components/ResumoTransacoes";
import { logout } from "../../services/auth";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function LancamentoDetalhado() {
  const [lancamento, setLancamento] = useState();
  const [transacoes, setTransacoes] = useState([]);
  const [cliente, setCliente] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [isOpenAlertDanger, setIsOpenAlertDanger] = useState(false);
  const [dangerMsg, setDangerMsg] = useState("");
  const [atualizar, setAtualizar] = useState(0);

  
  const [transactionFocus, setTransactionFocus] = useState({
    status: false,
    _id: "",
    titulo: "",
    data: 12123213,
    dataPadrao: "",
    valor: 0,
    valorPadrao: 0,
    lancamento: "",
    cliente: "",
    createdAt: "",
    clienteNome: "",
    valorApagar:0,
    tipo: "",
  });
  useEffect(() => {
    let id = window.location.pathname.split("/lancamento/")[1];
    let token = getUser().token;
    let clienteID;
    api.defaults.headers.authorization = `Bearer ${token}`;
    api.get(`/lancamento/list/${id}`).then((response) => {
      setLancamento(response.data);
      api.get(`/cadastro/list/${response.data.cliente}`).then((response) => {
        setCliente(response.data);
      });
    }).catch((err)=>{console.log(err)
      if(err.response.status === 401){
        logout()
      }
    });
    api.get(`/transacao/list/${id}`).then((response) => {
      setTransacoes(response.data);
    });
  }, [atualizar]);
  function closeAlert() {
    setIsOpenModal(false);
    setTransactionFocus({
      status: false,
      _id: "",
      titulo: "",
      data: 12123213,
      dataPadrao: "",
      valor: 0,
      valorPadrao: 0,
      lancamento: "",
      cliente: "",
      createdAt: "",
      clienteNome: "",
      tipo: "",
    });
    setIsOpenAlert(false);
    setAtualizar(atualizar + 1);
  }
  const [loading, setLoading] = useState(false);
  function enviarPagamento(dados) {
    setLoading(true);
    api
      .post("recebimento/registrar", dados)
      .then((response) => {
        console.log(response.data);
        setIsOpenAlert(true);
      })
      .catch((err) => {
        console.log(err);
        // setDangerMsg("Ocorreu um erro tente novamente.");
        setDangerMsg(JSON.stringify(err.response));
        setIsOpenAlertDanger(true);
        if (err.response.status === 401) {
          logout();
        }
      }).finally(()=>{
        setLoading(false)
      });
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <h2 style={{ marginBottom: "1rem" }}>Lançamento</h2>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={3}>
              <strong>Valor emprestado:</strong>
              <br /> {currency(lancamento?.valor)}
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <strong>Data do empréstimo:</strong>
              <br /> {lancamento && dateBr(lancamento?.dataLancamento)}
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <strong>Data 1 parcela:</strong> <br />{" "}
              {lancamento && dateBr(lancamento?.dataPrimeiraParcela)}
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <strong>Taxa de juros:</strong>
              <br /> {lancamento?.taxaJuro.toFixed(2)} %
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <strong>Intervalo de dias:</strong>
              <br /> {lancamento?.intervaloDias}
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <strong>Modelo de cobrança:</strong>
              <br />
              {lancamento?.modeloCobranca === "1" &&
                "Juros em parcelas e capital na última parcela"}
              {lancamento?.modeloCobranca === "2" &&
                "Juros em parcelas e capital diluído"}
              {lancamento?.modeloCobranca === "3" &&
                "Troca de cheque"}
              {lancamento?.modeloCobranca === "4" &&
                "Personalizado"}
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <strong>cliente:</strong>
              <br />
              {cliente?.nome}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card>
              <h3>Transações do lançamento</h3>
              <Grid className="tableResponsive">
                <table className="tableWrapper">
                  <thead className="tableHead">
                    <tr className="tableRow">
                      <th>Situação</th>
                      <th>Parcela</th>
                      <th>Data</th>
                      <th>Cliente</th>
                      <th>Valor</th>
                      <th>Valor recebido</th>
                      <th>Ação</th>
                    </tr>
                  </thead>
                  <tbody className="tableBody">
                    {transacoes.map((item, i) => (
                      <tr className="tableRow" key={i}>
                        <td style={{ fontSize: "1.7rem", textAlign: "center" }}>
                          {new Date(item.data).getTime() <
                            new Date(Date.now()).getTime() &&
                            item.status !== true && (
                              <p style={{ color: "#f46e6e" }}>
                                <AiFillCloseCircle />
                              </p>
                            )}
                          {new Date(item.data).getTime() >=
                            new Date(Date.now()).getTime() &&
                            item.status !== true && (
                              <p style={{ color: "#FFB95B" }}>
                                <MdSchedule />
                              </p>
                            )}
                          {item.status === true && (
                            <p style={{ color: "#4AD991" }}>
                              <AiFillCheckCircle />
                            </p>
                          )}
                        </td>

                        <td>{item.titulo}</td>
                        <td>{dateBr(item.data)}</td>
                        <td>{item.clienteNome}</td>
                        {/* <td>{item.observacao}</td> */}
                        <td>{currency(item.valor)}</td>
                        <td>{currency(item.pago)}</td>
                        <td>
                          <Grid className="actions">
                            <Button
                              className="actionBtn badge-success"
                              onClick={() => {
                                setIsOpenModal(true);
                                setTransactionFocus({
                                  status: item.status,
                                  _id: item._id,
                                  titulo: item.titulo,
                                  data: item.data,
                                  dataPadrao: item.dataPadrao,
                                  valor: item.valor,
                                  valorPadrao: item.valorPadrao,
                                  lancamento: item.lancamento,
                                  cliente: item.cliente,
                                  createdAt: item.createdAt,
                                  clienteNome: item.clienteNome,
                                  tipo: item.tipo,
                                  valorApagar: item.valorApagar,
                                  obs: item.obs,

                                });
                              }}
                            >
                              <i className="fa fa-eye"></i>
                            </Button>
                          </Grid>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card title="Resumo do mês">
              <ResumoTransacoes transacoes={transacoes} lancamento={lancamento} />
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar open={isOpenAlert} autoHideDuration={800} onClose={closeAlert}>
        <Alert onClose={closeAlert} severity="success">
          Pagamento registrado com sucesso!
        </Alert>
      </Snackbar>
      <Snackbar
        open={isOpenAlertDanger}
        autoHideDuration={1000}
        onClose={() => setIsOpenAlertDanger(false)}
      >
        <Alert onClose={() => setIsOpenAlertDanger(false)} severity="error">
          {dangerMsg}
        </Alert>
      </Snackbar>
      <DefaultModal
        loading={loading}
        button="modal"
        maxWidth="lg"
        buttonClass="actionBtn badge-success"
        transaction={transactionFocus}
        close={() => setIsOpenModal(false)}
        isOpen={isOpenModal}
        handleEnviarPagamento={enviarPagamento}
        loa
      />
    </Grid>
  );
}
