import { Grid, Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { getUser, logout } from "../../services/auth";
import api from "../../services/axios";
import Card from "../../components/Card/index.js";
import { currency, dateBr } from "../../intl";
import { Link } from "react-router-dom";

export function Lancamentos() {
  const [lancamentos, setLancamentos] = useState([]);
  
  useEffect(() => {
    let token = getUser().token;
    api.defaults.headers.authorization = `Bearer ${token}`;
    api
      .get("/lancamento/list")
      .then((response) => {
        setLancamentos(response.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          logout();
        }
      });
  }, []);

  function removerLancamento(id){
    let x = window.confirm(`Tem certeza que deseja remover este lançamento?`)
    if(x){
      let token = getUser().token;
      api.defaults.headers.authorization = `Bearer ${token}`;
      api
        .delete(`/lancamento/delete/${id}`)
        .then((response) => {
          window.location.reload()
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            logout();
          }
        });
    }
  }

  return (
    <>
      <Helmet>
        <title>Lançamentos</title>
        {/* <meta name="description" content="Description of ProfilePage" /> */}
      </Helmet>
      <h2 className="breadcumbTitle">Lançamentos</h2>
      <Grid container spacing={3}>
        {[...lancamentos].reverse().map((lancamento, key) => (
          <Grid item xs={12} sm={6} md={4} key={key}>
            <Card>
              <div style={{display: 'flex', justifyContent:'space-between'}}>
                <h4>{"Lancamento " + (lancamentos.length - Number(key))}</h4>
                <Button className="actionBtn badge-danger" onClick={()=>removerLancamento(lancamento._id)}>
                  <i className="fa fa-trash"></i>
                </Button>
              </div>
              <strong>Valor:</strong> {currency(lancamento.valor)}
              <br />
              <strong>Data:</strong> {dateBr(lancamento.dataLancamento)}
              <br />
              <strong>Parcelas:</strong> {lancamento.parcelas}
              <br />
              <strong>Cliente:</strong> {lancamento.clienteNome}
              <br />
              <Button
                component={Link}
                to={`/lancamento/${lancamento._id}`}
                variant="contained"
                fullWidth
                color="primary"
                style={{ marginTop: " 1rem", textTransform: "unset" }}
              >
                {" "}
                Detalhar empréstimo
              </Button>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
