import React, { useEffect, useState, useRef } from "react";

import { Grid, Button, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Card from "../../components/Card/";
import api from "../../services/axios";
import { getUser, logout } from "../../services/auth";
import { Helmet } from "react-helmet";
import { currency, dateBr } from "../../intl";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { MdSchedule } from "react-icons/md";
import DefaultModal from "../../components/modal";

import "./style.scss";
import { ResumoTransacoes } from "../../components/ResumoTransacoes";

const meses = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function Transactions() {
  const [transacoes, setTransacoes] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [isOpenAlertDanger, setIsOpenAlertDanger] = useState(false);
  const [dangerMsg, setDangerMsg] = useState("");

  const [transactionFocus, setTransactionFocus] = useState({
    status: false,
    _id: "",
    titulo: "",
    data: 12123213,
    dataPadrao: "",
    valor: 0,
    valorPadrao: 0,
    lancamento: "",
    cliente: "",
    createdAt: "",
    clienteNome: "",
    valorApagar: 0,

    tipo: "",
  });

  const [mesFiltrado, setMesFiltrado] = useState(new Date().getMonth());
  const [anoFiltrado, setAnoFiltrado] = useState(new Date().getFullYear());
  const [atualizar, setAtualizar] = useState(0);
  const [recebimentos, setRecebimentos] = useState([]);

  useEffect(() => {
    let token = getUser().token;
    api.defaults.headers.authorization = `Bearer ${token}`;
    api
      .get(`transactions/list?data=${anoFiltrado}-${mesFiltrado + 1}-01`)
      .then((response) => {
        setTransacoes(response.data);
        console.log(response.data);
      });

    api
      .get(`/recebimentos/lista/`)
      .then((response) => {
        let newRecebimentos = [];
        for (let i of response.data) {
          for (let a in newRecebimentos) {
            if (newRecebimentos[a].id === i._id) {
              newRecebimentos[a].valor = newRecebimentos[a].valor + i.valor;
            } else {
              newRecebimentos.push({ valor: i.valor, id: i._id });
            }
          }
        }
        setRecebimentos(newRecebimentos);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          logout();
        }
      });
  }, [mesFiltrado, anoFiltrado, atualizar]);
  function mesAnterior() {
    if (mesFiltrado > 0) {
      setMesFiltrado(mesFiltrado - 1);
    } else {
      setMesFiltrado(11);
      setAnoFiltrado(anoFiltrado - 1);
    }
  }

  function mesSeguinte() {
    if (mesFiltrado < 11) {
      setMesFiltrado(mesFiltrado + 1);
    } else {
      setMesFiltrado(0);
      setAnoFiltrado(anoFiltrado + 1);
    }
  }

  function closeAlert() {
    setIsOpenModal(false);
    setTransactionFocus({
      status: false,
      _id: "",
      titulo: "",
      data: 12123213,
      dataPadrao: "",
      valor: 0,
      valorPadrao: 0,
      lancamento: "",
      cliente: "",
      createdAt: "",
      clienteNome: "",
      tipo: "",
    });
    setIsOpenAlert(false);
    setAtualizar(atualizar + 1);
  }

  function enviarPagamento(dados) {
    api
      .post("recebimento/registrar", dados)
      .then((response) => {
        console.log(response.data);
        setIsOpenAlert(true);
      })
      .catch((err) => {
        console.log(err);
        setDangerMsg("Ocorreu um erro tente novamente.");
        setIsOpenAlertDanger(true);
        if (err.response.status === 401) {
          logout();
        }
      });
  }

  return (
    <>
      <Helmet>
        <title>Transações</title>
        <meta name="description" content="Description of ProfilePage" />
      </Helmet>
      <h2 className="breadcumbTitle">Transações</h2>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card>
            <Grid className="calendarHeader">
              <Grid className="titleHeader">
                <Button className="arrowBtn" onClick={mesAnterior}>
                  <i className="fa fa-angle-left" />
                </Button>
                <Grid className="headerText">
                  {/* {mesFiltrado + "/" + anoFiltrado !== mesAtual && (
                   <>
                     <Button
                    size="small"
                    variant="contained"
                      onClick={() => {
                        setAnoFiltrado(new Date().getFullYear());
                        setMesFiltrado(new Date().getMonth());
                      }}
                    >
                      Mês atual
                    </Button>
                    <br/>
                   </>

                  )} */}
                  {meses[mesFiltrado]} {anoFiltrado}
                </Grid>
                <Button className="arrowBtn" onClick={mesSeguinte}>
                  <i className="fa fa-angle-right" />
                </Button>
              </Grid>
              {/* <DayNames /> */}
            </Grid>
            <Grid className="tableResponsive">
              <table className="tableWrapper">
                <thead className="tableHead">
                  <tr className="tableRow">
                    <th>Situação</th>
                    <th>Parcela</th>
                    <th>Data</th>
                    <th>Cliente</th>
                    <th>Valor</th>
                    <th>Valor recebido</th>
                    <th>Ação</th>
                  </tr>
                </thead>
                <tbody className="tableBody">
                  {transacoes.map((item, i) => (
                    <tr className="tableRow" key={i}>
                      <td style={{ fontSize: "1.7rem", textAlign: "center" }}>
                        {new Date(item.data).getTime() <
                          new Date(Date.now()).getTime() &&
                          item.status !== true && (
                            <p style={{ color: "#f46e6e" }}>
                              <AiFillCloseCircle />
                            </p>
                          )}
                        {new Date(item.data).getTime() >=
                          new Date(Date.now()).getTime() &&
                          item.status !== true && (
                            <p style={{ color: "#FFB95B" }}>
                              <MdSchedule />
                            </p>
                          )}
                        {item.status === true && (
                          <p style={{ color: "#4AD991" }}>
                            <AiFillCheckCircle />
                          </p>
                        )}
                      </td>

                      <td>{item.titulo}</td>
                      <td>{dateBr(item.data)}</td>
                      <td>{item.clienteNome}</td>
                      {/* <td>{item.observacao}</td> */}
                      <td>{currency(item.valor)}</td>
                      <td>{currency(item.pago)}</td>
                      <td>
                        <Grid className="actions">
                          <Button
                            className="actionBtn badge-success"
                            onClick={() => {
                              setIsOpenModal(true);
                              setTransactionFocus({
                                status: item.status,
                                _id: item._id,
                                titulo: item.titulo,
                                data: item.data,
                                dataPadrao: item.dataPadrao,
                                valor: item.valor,
                                valorPadrao: item.valorPadrao,
                                lancamento: item.lancamento,
                                cliente: item.cliente,
                                createdAt: item.createdAt,
                                clienteNome: item.clienteNome,
                                valorApagar: item.valorApagar,
                                obs:item.obs,
                                tipo: item.tipo,
                              });
                            }}
                          >
                            <i className="fa fa-eye"></i>
                          </Button>
                        </Grid>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card title="Resumo do mês">
            <ResumoTransacoes transacoes={transacoes} />
          </Card>
        </Grid>
      </Grid>
      <Snackbar open={isOpenAlert} autoHideDuration={800} onClose={closeAlert}>
        <Alert onClose={closeAlert} severity="success">
          Pagamento registrado com sucesso!
        </Alert>
      </Snackbar>
      <Snackbar
        open={isOpenAlertDanger}
        autoHideDuration={1000}
        onClose={() => setIsOpenAlertDanger(false)}
      >
        <Alert onClose={() => setIsOpenAlertDanger(false)} severity="error">
          {dangerMsg}
        </Alert>
      </Snackbar>
      <DefaultModal
        button="modal"
        maxWidth="lg"
        buttonClass="actionBtn badge-success"
        transaction={transactionFocus}
        close={() => setIsOpenModal(false)}
        isOpen={isOpenModal}
        handleEnviarPagamento={enviarPagamento}
      />
    </>
  );
}
