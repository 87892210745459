import React, {useState, Component, useEffect } from 'react';
import {Route, Redirect} from 'react-router-dom';

import { FullScreen } from "react-full-screen";
import {Grid} from '@material-ui/core';
import Header from '../components/Header/index.js';
import SidebarNav from '../components/SidebarNav/index.js';
import {isAuthenticated} from "../services/auth.ts"

import './style.scss';

const PrivateRoute = props => {
  const [colupsMenu, setColupsMenu] = useState(false);
  const [sidebarSetting, setSidebarSetting] = useState(false);
  const [screen, setScreen] = useState(false);
  const [state, setState] = useState({
    collapsable_menu: false,
    top_menu: false,
    squared_card: false,
    block_shadow: false,
  });

  useEffect(()=>{
    isAuthenticated()
  },[])


  const colupsMenuHandler = () => {
    setColupsMenu(!colupsMenu);
  };
  const fullScreenHandler = () => {
    setScreen(!screen);
  };
  return (
    <>
  {
    isAuthenticated() ? 
    <FullScreen handle={screen}>
      <>
        <Grid
          className={colupsMenu ? 'mainContainer mainContainerColups' : 'mainContainer' && state.collapsable_menu ? 'mainContainer mainContainerColups' : 'mainContainer'}>
          <SidebarNav
            colupsMenuHandler={colupsMenuHandler}
            colupsMenu={colupsMenu}
            setSidebarSetting={setSidebarSetting}
          />
          <Grid
            className={`mainContentRouter ${
              state.squared_card ? 'squareBoxCard' : ''
              }`}>
            <Grid className={state.block_shadow ? 'withoutShadowCard' : ''}>
              <Header
                colupsMenuHandler={colupsMenuHandler}
                fullScreenHandler={fullScreenHandler}
              />
              <Grid className="mainCotentInner">
                <Route
                  {...props}
                  exact
                  render={props => <Component {...props} />}
                />
              </Grid>
            </Grid>
          </Grid>
          {sidebarSetting && (
            <Grid
              onClick={() => setSidebarSetting(false)}
              className="backDrop"
            />
          )}
        </Grid>
      </>
  </FullScreen>
: <Redirect to={{
  pathname: "/",
}}/>
  }
  </>
  );
};


export default PrivateRoute;
