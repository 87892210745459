import React, { Fragment, useEffect, useState } from "react";


import { Grid, Button, Snackbar } from "@material-ui/core";


import { currency, dateBr } from "../../intl";
import "./style.scss";

import api from "../../services/axios.ts";
import { getUser } from "../../services/auth.ts";
import { ResumoTransacoes } from "../../components/ResumoTransacoes";
import { logout } from "../../services/auth";
import { Link } from "react-router-dom";
export function ListTransactions({ transacoes }) {

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [transactionFocus, setTransactionFocus] = useState({
    status: false,
    _id: "",
    titulo: "",
    data: 12123213,
    dataPadrao: "",
    valor: 0,
    valorPadrao: 0,
    lancamento: "",
    cliente: "",
    createdAt: "",
    clienteNome: "",
    tipo: "",
  });

  return (
    <Grid className="tableResponsive resumDashboardCard">
      <table className="tableWrapper">
        <tbody className="tableBody">
          {transacoes.map((item, i) => (
              <>
                {
                    <tr className="tableRow" key={i}>
                        
                    <td>{currency(item.valor)}</td>
                    <td>{item.clienteNome}</td>
                    <td>
                      <Grid className="actions">
                        <Button
                          className="actionBtn badge-success"
                          component={Link}
                          to={`/lancamento/${item.lancamento}`}
                        //   onClick={() => {
                        //     setIsOpenModal(true);
                        //     setTransactionFocus({
                        //       status: item.status,
                        //       _id: item._id,
                        //       titulo: item.titulo,
                        //       data: item.data,
                        //       dataPadrao: item.dataPadrao,
                        //       valor: item.valor,
                        //       valorPadrao: item.valorPadrao,
                        //       lancamento: item.lancamento,
                        //       cliente: item.cliente,
                        //       createdAt: item.createdAt,
                        //       clienteNome: item.clienteNome,
                        //       tipo: item.tipo,
                        //     });
                        //   }}
                        >
                          <i className="fa fa-eye"></i>
                        </Button>
                      </Grid>
                    </td>
                  </tr>
                
                }
              </>
          ))}
        </tbody>
      </table>
    </Grid>
  );
}
