import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { Grid, Button } from "@material-ui/core";
import EditProfile from "../EditProfile/index.jsx";
import { Link } from "react-router-dom";
import "./style.scss";
import api from "../../services/axios.ts";
import { getUser } from "../../services/auth.ts";
import { EditClientForm } from "../EditClientForm/index.jsx";
import { logout } from "../../services/auth.ts";

export function EditClient() {
  const [client, setClient] = useState();
  useEffect(() => {
    let id = window.location.pathname.split("/cliente/")[1];
    let token = getUser().token;
    api.defaults.headers.authorization = `Bearer ${token}`;
    api.get(`cadastro/list/${id}`).then((response) => {
      setClient(response.data);
    }).catch((err)=>{console.log(err)
      if(err.response.status === 401){
        logout()
      }
    });
  }, []);
  console.log(client);
  return (
    <Fragment>
      <Helmet>
        <title>ProfilePage</title>
        <meta name="description" content="Description of ProfilePage" />
      </Helmet>
      <Grid container spacing={3}>
        <Grid xl={9} lg={8} xs={12}>
          <h2
            className="breadcumbTitle"
            style={{ width: "100%", marginBottom: "1rem" }}
          >
            Editar cliente
          </h2>
        </Grid>
        <Grid xl={3} lg={4} xs={12}>
          <Button
            component={Link}
            variant="contained"
            color="primary"
            to="/clientes"
            style={{ width: "100%", marginBottom: "2rem" }}
          >
            Ver lista de clientes
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xl={12} lg={12} xs={12}>
          <EditClientForm client={client} />
        </Grid>
      </Grid>
    </Fragment>
  );
}
