import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Grid } from '@material-ui/core';


import './style.scss';
import { isAuthenticated } from '../services/auth';

export class PublicRoute extends React.Component {


    render() {
        return (
            <Grid>
                {
                    isAuthenticated() ?  <Redirect to={{
                        pathname: "/dashboard",
                      }}/>:<Route
                    {...this.props}
                    exact
                    render={props => <Component {...props} />}
                />  
                }
            </Grid>
        );
    }
}

export default PublicRoute;
