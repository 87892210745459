import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

import { Grid} from "@material-ui/core";
import "./style.scss";
import { NewLauncherForm } from "../NewLauncherForm/index.jsx";

export function NewLauncher() {
    // const [ client, setClient] = useState();
    // useEffect(()=>{
    //     let id = window.location.pathname.split("/cliente/")[1];
    //     let token = getUser().token;
    //     api.defaults.headers.authorization = `Bearer ${token}`;
    //     api.get(`cadastro/list/${id}`).then((response) => {
    //         setClient(response.data);

    //       });
        

    // },[])
    // console.log(client)
  return (
    <Fragment>
      <Helmet>
        <title>Novo lançamento</title>
        <meta name="description" content="Description of ProfilePage" />
      </Helmet>
      <Grid container spacing={3}>
        <Grid xl={9} lg={8} xs={12}>
          <h2
            className="breadcumbTitle"
            style={{ width: "100%", marginBottom: "1rem" }}
          >
              Novo lançamento 
          </h2>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xl={12} lg={12} xs={12}>
          <NewLauncherForm/>
        </Grid>
      </Grid>
    </Fragment>
  );
}
