import React, { useEffect, useState } from "react";

import InputMask from "react-input-mask";

import Card from "../../components/Card/index.js";
import {
  Grid,
  TextField,
  OutlinedInput,
  FormControl,
  Select,
  Button,
} from "@material-ui/core";
import "./style.scss";
import api from "../../services/axios";
import SweetAlertSingle from "../NewLauncherForm/alert.js";
import { renderToStaticMarkup } from "react-dom/server";
import { getUser, logout } from "../../services/auth";
import { Redirect } from "react-router";

import { FaTrashAlt, FaPhone } from "react-icons/fa";


const statesSelect = [
  { value: "AC", name: "Acre" },
  { value: "AL", name: "Alagoas" },
  { value: "AP", name: "Amapá" },
  { value: "AM", name: "Amazonas" },
  { value: "BA", name: "Bahia" },
  { value: "CE", name: "Ceará" },
  { value: "DF", name: "Distrito Federal" },
  { value: "ES", name: "Espírito Santo" },
  { value: "GO", name: "Goiás" },
  { value: "MA", name: "Maranhão" },
  { value: "MT", name: "Mato Grosso" },
  { value: "MS", name: "Mato Grosso do Sul" },
  { value: "MG", name: "Minas Gerais" },
  { value: "PA", name: "Pará" },
  { value: "PB", name: "Paraíba" },
  { value: "PR", name: "Paraná" },
  { value: "PE", name: "Pernambuco" },
  { value: "PI", name: "Piauí" },
  { value: "RJ", name: "Rio de Janeiro" },
  { value: "RN", name: "Rio Grande do Norte" },
  { value: "RS", name: "Rio Grande do Sul" },
  { value: "RO", name: "Rondônia" },
  { value: "RR", name: "Roraima" },
  { value: "SC", name: "Santa Catarina" },
  { value: "SP", name: "São Paulo" },
  { value: "SE", name: "Sergipe" },
  { value: "TO", name: "Tocantins" },
  { value: "EX", name: "Estrangeiro" },
];

export function EditClientForm({ client }) {
  const [cliente, setCliente] = useState(client);
  const [nome, setNome] = useState(client ? client.nome : "");
  const [telefone, setTelefone] = useState([]);
  const [telefoneField, setTelefoneField] = useState([]);
  const [email, setEmail] = useState();
  const [redirectToClients, setRedirectToClients] = useState(false);
  const [redirectToLaunch, setRedirectToLaunch] = useState(false);
  const [endereco, setEndereco] = useState({
    cep: "",
    rua: "",
    bairro: "",
    numero: "",
    estado: "",
    cidade: "",
  });
  const [success, setSuccess] = useState(false);
  const [datanascimento, setDatanascimento] = useState();
  const [observacao, setObservacao] = useState();
  const [cpf, setCpf] = useState();

  function sendFormLogin(event) {
    event.preventDefault();
    let token = getUser().token;
    api.defaults.headers.authorization = `Bearer ${token}`;
    api
      .put(`/client/update/${client._id}`, {
        nome,
        telefone,
        cpf,
        email,
        enderecoCep: endereco.cep,
        enderecoRua: endereco.rua,
        enderecoNumero: endereco.numero,
        enderecoEstado: endereco.estado,
        enderecoCidade: endereco.cidade,
        datanascimento,
        observacao,
      })
      .then((response) => {
        setSuccess(true);
      })
      .catch((err)=>{console.log(err)
        if(err.response.status === 401){
          logout()
        }
      });
  }

  useEffect(() => {
    setCliente(client);
    setNome(client?.nome);
    setEmail(client?.email);
    setCpf(client?.cpf);
    setDatanascimento(client?.datanascimento);
    setEndereco({
      cep: client?.enderecoCep,
      cidade: client?.enderecoCidade,
      estado: client?.enderecoEstado,
      numero: client?.enderecoNumero,
      rua: client?.enderecoRua,
    });
    setTelefone(client?.telefone)
    setObservacao(client?.observacao)
  }, [client]);

  function cepApi() {
    console.log(endereco.cep.replace(/_/g, "").replace(/-/g, ""));
    if (endereco.cep.replace(/_/g, "").replace(/-/g, "").length === 8) {
      console.log(endereco.cep.replace(/_/g, "").replace(/-/g, ""));
      fetch(`https://viacep.com.br/ws/${endereco.cep}/json/`)
        .then((res) => res.json())
        .then((result) => {
          setEndereco({
            ...endereco,
            rua: result.logradouro,
            bairro: result.bairro,
            cidade: result.localidade,
            estado: result.uf,
          });
        })
        .catch((err) => {
          alert("cep não encontrado!");
        });
    }
  }

  function removeTel(key) {
    console.log(key);
    let newTels = telefone;
    setTelefone([]);
    setTimeout(() => {
      newTels.splice(key, 1);
      setTelefone(newTels);
      console.log(telefone);
    }, 50);
  }
  function addTel() {
    let text = telefoneField.replace(/\D/g, "");
    if (telefone.indexOf(telefoneField) === -1 && text.length === 11) {
      setTelefone([...telefone, telefoneField]);
      setTelefoneField("");
    }
  }

  return (
    <Card className="editProfile">
      <form onSubmit={sendFormLogin}>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <label htmlFor="first_name">Nome completo</label>

            <TextField
              name="nome"
              variant="outlined"
              className="textField"
              // value={cliente?.nome}
              required
              fullWidth
              onChange={(event) => setNome(event.target.value)}
              value={nome}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <label htmlFor="last_name">E-mail</label>
            <TextField
              name="email"
              variant="outlined"
              className="textField"
              fullWidth
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <label htmlFor="phone">CPF</label>
            <InputMask
              mask="999.999.999-99"
              value={cpf}
              onChange={(event) => setCpf(event.target.value)}
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  type="tel"
                  name="cpf"
                  variant="outlined"
                  className="textField"
                  fullWidth
                />
              )}
            </InputMask>
          </Grid>
          <Grid item sm={6} xs={12}>
            <label htmlFor="email">Data de nascimento</label>
            <TextField
              type="date"
              name="email"
              variant="outlined"
              className="textField"
              fullWidth
              value={datanascimento}
              onChange={(event) => setDatanascimento(event.target.value)}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <h3>Endereço:</h3>
          </Grid>
          <Grid item sm={6} xs={12}>
            <label htmlFor="first_name">CEP</label>
            <InputMask
              mask="99999-999"
              value={endereco.cep}
              onChange={(event) =>
                setEndereco({ ...endereco, cep: event.target.value })
              }
              onBlur={cepApi}
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  name="cep"
                  variant="outlined"
                  className="textField"
                  fullWidth
                />
              )}
            </InputMask>
          </Grid>
          <Grid item sm={4} xs={8}>
            <label htmlFor="first_name">Rua</label>
            <TextField
              name="rua"
              variant="outlined"
              className="textField"
              fullWidth
              value={endereco.rua}
              onChange={(event) =>
                setEndereco({ ...endereco, rua: event.target.value })
              }
              autoComplete="off"
            />
          </Grid>
          <Grid item sm={2} xs={4}>
            <label htmlFor="first_name">Numero</label>
            <TextField
              name="numero"
              variant="outlined"
              className="textField"
              fullWidth
              value={endereco.numero}
              onChange={(event) =>
                setEndereco({ ...endereco, numero: event.target.value })
              }
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <label htmlFor="first_name">Estado</label>
            <FormControl fullWidth className="textField" variant="outlined">
              <Select
                native
                name="estado"
                variant="outlined"
                value={endereco.estado}
                input={
                  <OutlinedInput name="age" id="outlined-age-native-simple" />
                }
                onChange={(event) =>
                  setEndereco({ ...endereco, estado: event.target.value })
                }
              >
                <option>Selecione</option>
                {statesSelect.map((state) => (
                  <option value={state.value}>{state.name}</option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={12}>
            <label htmlFor="date">Cidade</label>
            <TextField
              name="date_of_birth"
              variant="outlined"
              className="textField"
              fullWidth
              value={endereco.cidade}
              onChange={(event) =>
                setEndereco({ ...endereco, cidade: event.target.value })
              }
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <h3>Contato:</h3>
          </Grid>
          <Grid item sm={10} xs={10}>
            <label htmlFor="date">Telefone</label>
            <InputMask
              mask="(99) 99999-9999"
              onChange={(event) => {
                setTelefoneField(event.target.value);
              }}
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  name="date_of_birth"
                  variant="outlined"
                  className="textField"
                  fullWidth
                />
              )}
            </InputMask>
          </Grid>
          <Grid item sm={2} xs={12} style={{ display: "flex" }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => addTel()}
              style={{ height: "56px", padding: "18.5px 14px", marginTop: "auto" }}
            >
              Adicionar &nbsp; +<FaPhone/>
            </Button>
          </Grid>
          <Grid item sm={12} xs={12}>
            <ul>
              {telefone?.map((tel, key) => (
                <li key={key}>
                  {key + 1} - {tel}{" "}
                  <span className="remove-Icon" onClick={() => removeTel(key)}>
                    <FaTrashAlt />
                  </span>
                </li>
              ))}
            </ul>
          </Grid>
          <Grid item sm={12} xs={12}>
            <label htmlFor="date">Observação</label>
            <TextField
              name="date_of_birth"
              variant="outlined"
              className="textField"
              value={observacao}
              fullWidth
              onChange={(event) => {
                setObservacao(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Button type="submiit" className="btn bg-success" fullWidth>
              Salvar
            </Button>
          </Grid>
        </Grid>
      </form>
      <SweetAlertSingle
        title="Operação concluída"
        type="success"
        show={success}
        success
        confirmButtonText="Novo lançamento"
        cancelButtonText="Lista de clientes"
        showCancelButton={true}
        onConfirm={() => {
          setSuccess(false)
          setRedirectToLaunch(true)
        
        }}
        onCancel={() => {
          setSuccess(false)
          setRedirectToClients(true)}}
        text={renderToStaticMarkup(
          <span>
            Cliente Editado com sucesso.
            <br />
          </span>
        )}
        showLoaderOnConfirm={true}
      />
      {redirectToLaunch && <Redirect to="/novo-lancamento" />}
      {redirectToClients && <Redirect to="/clientes" />}
    </Card>
  );
}
