import React, { useEffect, useState } from "react";

import { Grid, Button } from "@material-ui/core";
import Card from "../../components/Card/";
import { Link } from "react-router-dom";
import api from "../../services/axios";
import { getUser, logout } from "../../services/auth";
import { Helmet } from "react-helmet";

export function InvoiceLists() {
  const [clients, setClients] = useState([]);
  useEffect(() => {
    let token = getUser().token;
    api.defaults.headers.authorization = `Bearer ${token}`;
    api
      .get("cadastro/list")
      .then((response) => {
        setClients(response.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          logout();
        }
      });
  }, []);
  return (
    <>
      <Helmet>
        <title>Clientes</title>
        <meta name="description" content="Description of ProfilePage" />
      </Helmet>
      <h2
        className="breadcumbTitle"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        Clientes{" "}
        <Button
          variant="contained"
          component={Link}
          to="/adicionar-cliente"
          color="primary"
        >
          Adicionar cliente
        </Button>
      </h2>
      <Card>
        <Grid className="tableResponsive">
          <table className="tableWrapper">
            <thead className="tableHead">
              <tr className="tableRow">
                <th>#</th>
                <th>Cliente</th>
                <th>Telefone</th>
                <th>E-mail</th>
                <th>Empréstimos</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody className="tableBody">
              {clients.map((item, i) => (
                <tr className="tableRow" key={i}>
                  <td>
                    <strong>{i + 1}</strong>
                  </td>
                  <td>{item.nome}</td>
                  <td>{item.telefone[0]}</td>
                  <td>{item.email}</td>
                  {/* <td>{item.paid}</td> */}
                  <td>{item.lancamentos.length}</td>
                  <td>
                    <Grid className="actions">
                      <Button
                        component={Link}
                        to={`/vizualizar-cliente/${item._id}`}
                        className="actionBtn badge-success"
                      >
                        <i className="fa fa-eye"></i>
                      </Button>
                      <Button
                        component={Link}
                        to={`/cliente/${item._id}`}
                        className="MuiButtonBase-root MuiButton-root MuiButton-text actionBtn bg-primary "
                      >
                        <i className="fa fa-pencil"></i>
                      </Button>
                    </Grid>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Grid>
      </Card>
    </>
  );
}
