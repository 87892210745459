import { Grid, TextField, Button, InputAdornment } from "@material-ui/core";
import { FormEvent, useState } from "react";
import { Helmet } from "react-helmet";

import loginImg from "../../assets/images/login-bg.png";
import { login } from "../../services/auth";
import api from "../../services/axios";

export function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showpass, setShowpass] = useState(false);
  const [error, setError] = useState<{
    email: string;
    password: string;
  }>({
    email: "",
    password: "",
  });

  function showpasshandler() {
    setShowpass(!showpass);
  }

  function handleLogin(event: FormEvent) {
    event.preventDefault();
    if(!email){
      setError({
        email:"Digite um e-mail!", 
        password:""
      })
    }
    
    else if(!password){
      setError({
        email:"", 
        password:"Digite uma senha válida!"
      })
    }
    else{
      setError({
        email:"", 
        password:""
      })
      api
      .post("user/login", {
        email,
        password,
      })
      .then((response) => {
        console.log(response.data);
        login(JSON.stringify(response.data));
      })
      .catch((err) => {
        if(err.response.status === 404){
          setError({
            email:"Usuário não encontrado!", 
            password:""
          })
        }
        if(err.response.status === 401){
          setError({
            email:"", 
            password:"Senha incorreta!"
          })
        }
        console.error(err.response)});
    }
  }

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Grid className="accountArea">
        <form className="accountWrapper" onSubmit={handleLogin}>
          <Grid
            className="bgShaperight"
            style={{
              background: `url(${loginImg})left center/cover no-repeat`,
            }}
          ></Grid>
          <Grid className="accountForm">
            <div className="fromTitle">
              <h2>Entrar </h2>
            </div>
            <TextField
              label="Email"
              placeholder="Digite seu email aqui..."
              fullWidth
              variant="outlined"
              name="email"
              onChange={(event: any) => setEmail(event.target.value)}
              // value={this.state.email}
              InputLabelProps={{
                shrink: true,
              }}
              error={error?.email ? true : false}
              helperText={error.email && error.email}
              className="formInput"
              type="email"
            />
            <TextField
              label="Senha"
              placeholder="Digite sua senha aqui..."
              fullWidth
              type={showpass ? "text" : "password"}
              variant="outlined"
              name="password"
              onChange={(event: any) => setPassword(event.target.value)}
              // value={password}
              InputLabelProps={{
                shrink: true,
              }}
              error={error?.password ? true : false}
              helperText={error.password && error.password}
              className="formInput"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    onClick={showpasshandler}
                    className="showPassword"
                    position="end"
                  >
                    <i className={showpass ? "fa fa-eye" : "fa fa-eye-slash"} />
                  </InputAdornment>
                ),
              }}
            />
            <Button type="submit" className="btn bg-default accountBtn">
              Entrar
            </Button>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
