import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

import { Grid, Button } from "@material-ui/core";
import EditProfile from "../EditProfile/index.jsx";
import { Link } from "react-router-dom";
import "./style.scss";


export function ProfilePage() {
  return (
    <Fragment>
      <Helmet>
        <title>Adicionar Cliente</title>
        <meta name="description" content="Description of ProfilePage" />
      </Helmet>
      <Grid container spacing={3}>
        <Grid xl={9} lg={8} xs={12}>
          <h2 className="breadcumbTitle"style={{width:"100%", marginBottom:"1rem"}}>Adicionar cliente</h2>
        </Grid>
        <Grid xl={3} lg={4} xs={12}>
          <Button
            component={Link}
            variant="contained"
            color="primary"
            to="/clientes"
            style={{width:"100%", marginBottom:"2rem"}}
          >
            Ver lista de clientes
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xl={12} lg={12} xs={12}>
          <EditProfile />
        </Grid>
      </Grid>
     
    </Fragment>
  );
}
