import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";

import "./style.scss";
import { currency } from "../../intl";

const Loans = ({resumProp}) => {
  const [resumo, setResumo] = useState({
      valorTotal:0,
      jurosTaxa: 0,
      lucro:0,
      valorEmprestado:0
    }
  )
  
  useEffect(()=>{
    setResumo(resumProp)
  },[resumProp])

  const loans = [
    {
      title: "Valor emprestado",
      amount: currency(resumo.valorEmprestado),
      percentage: (resumo.valorEmprestado/resumo.valorTotal)*100,
      color: "#F46E6E",
    },
    {
      title: "Taxa de juros/mês",
      amount: resumo.jurosTaxa + " %",
      percentage: resumo.jurosTaxa,
      color: "#F1682C",
  
  
    },
    {
      title: "Retorno lucro",
      amount: currency(resumo.lucro),
      percentage: resumo.lucro/resumo.valorTotal*100,
      color: "#0EB7FE",
    },
    {
      title: "Valor total",
      amount: currency(resumo.valorTotal),
      percentage: 100,
      color: "#03AE14",
    },
  ];
  return (
    <Grid className="loansWrapper">
      <Grid container spacing={3}>
        {loans.map((item, i) => {
          return (
            <Grid key={i} item sm={6} xs={12}>
              <Grid className="loanCard">
                <h4>{item.title}</h4>
                <Grid className="lbtm">
                  <h3>{item.amount}</h3>
                  <Grid className="lprogressbar">
                    <span className="prbg" style={{ background: item.color }} />
                    {/* <span
                      className="lpbar"
                      style={{
                        width: `${item.percentage}%`,
                        background: item.color,
                      }}
                    /> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default Loans;
