import { Button, Grid, Snackbar, TextField } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useState, useEffect } from "react";
import ReactInputMask from "react-input-mask";
import Card from "../../components/Card";
import { getUser, logout } from "../../services/auth";
import api from "../../services/axios";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function Wapi() {
  const [config, setConfig] = useState();
  const [token, setToken] = useState("");
  const [url, setUrl] = useState("");
  const [numero, setNumero] = useState("");
  const [isOpenAlertDanger, setIsOpenAlertDanger] = useState(false);
  const [isOpenAlertSuccess, setIsOpenAlertSuccess] = useState("");

  useEffect(() => {
    let token = getUser().token;
    let user = getUser().user._id;
    api.defaults.headers.authorization = `Bearer ${token}`;
    api
      .get(`/config/api/${user}`)
      .then((response) => {
        setConfig(response.data);
        setNumero(response.data.numero);
        setToken(response.data.token);
        setUrl(response.data.url);
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if(err.response.status){
            if (err.response.status === 401) {
                logout();
            }
          }
        }
      });
  }, []);

  function salvarConfigs(event) {
      event.preventDefault()
    if(config){
        api.put(`/edit/config/api/${config._id}`, {
      token,
      numero: numero.replace(/[^0-9]+/g, ""),
      url,
    }).then((response)=>{
        setConfig({...config, token, numero, url})
        setIsOpenAlertSuccess(true)
    }).catch((err)=>{
        setIsOpenAlertDanger(true)
    });
    }
  }

  return (
    <Grid container spacing={3}>
    <Grid item xs={12}>
        <h2>Configure sua api do WhatsApp</h2>
    </Grid>
      <Grid item xs={8}>
        <Card>
          {config ? (
            <>
              {" "}
              <iframe
                title="ifrme"
                src={`https://v1.utalk.chat/qrcode/${config.token}`}
                style={{
                  border: "10px",
                  overflow: "hidden",
                  width: "100%",
                  height: "310px",
                }}
                id="iframeqr"
              ></iframe>
            </>
          ) : (
            <>Carregando iframe...</>
          )}
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card>
          <form
            onSubmit={salvarConfigs}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <label htmlFor="first_name">Token</label>
                <TextField
                  name="nome"
                  variant="outlined"
                  className="textField"
                  value={token}
                  fullWidth
                  onChange={(event) => setToken(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="last_name">URL</label>
                <TextField
                  name="email"
                  variant="outlined"
                  className="textField"
                  fullWidth
                  value={url}
                  onChange={(event) => setUrl(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="date">Telefone</label>
                <ReactInputMask
                  mask="+55 (99) 99999-9999"
                  onChange={(event) => {
                    setNumero(event.target.value);
                  }}
                  value={numero}
                >
                  {(inputProps) => (
                    <TextField
                      {...inputProps}
                      name="date_of_birth"
                      variant="outlined"
                      className="textField"
                      fullWidth
                    />
                  )}
                </ReactInputMask>
              </Grid>
              <Grid item xs={12}>
                <Button type="submiit" className="btn bg-success" fullWidth>
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
      <Snackbar
        open={isOpenAlertDanger}
        autoHideDuration={1000}
        onClose={() => setIsOpenAlertDanger(false)}
      >
        <Alert onClose={() => setIsOpenAlertDanger(false)} severity="error">
          Ocorreu um erro ao enviar!
        </Alert>
      </Snackbar>
      <Snackbar
        open={isOpenAlertSuccess}
        autoHideDuration={1500}
        onClose={() => setIsOpenAlertSuccess(false)}
      >
        <Alert onClose={() => setIsOpenAlertSuccess(false)} severity="success">
          Configurações alteradas!
        </Alert>
      </Snackbar>
    </Grid>
  );
}
