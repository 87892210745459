import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { Grid, Button, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Card from "../../components/Card/";

import { currency, dateBr } from "../../intl";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { MdSchedule } from "react-icons/md";
import DefaultModal from "../../components/modal";

import api from "../../services/axios.ts";
import { getUser } from "../../services/auth.ts";
import { ResumoTransacoes } from "../../components/ResumoTransacoes";
import { logout } from "../../services/auth";
import { Link } from "react-router-dom";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function ClienteView() {
  const [lancamentos, setLancamentos] = useState([]);
  const [transacoes, setTransacoes] = useState([]);
  const [cliente, setCliente] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [isOpenAlertDanger, setIsOpenAlertDanger] = useState(false);
  const [dangerMsg, setDangerMsg] = useState("");
  const [atualizar, setAtualizar] = useState(0);

  const [transactionFocus, setTransactionFocus] = useState({
    status: false,
    _id: "",
    titulo: "",
    data: 12123213,
    dataPadrao: "",
    valor: 0,
    valorPadrao: 0,
    lancamento: "",
    cliente: "",
    createdAt: "",
    clienteNome: "",
    valorApagar: 0,
    tipo: "",
  });
  useEffect(() => {
    let id = window.location.pathname.split("/vizualizar-cliente/")[1];
    let token = getUser().token;
    let clienteID;
    api.defaults.headers.authorization = `Bearer ${token}`;

    api
      .get(`/cadastro/list/${id}`)
      .then((response) => {
        setCliente(response.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          logout();
        }
      });
    api.get(`/transactions/listbyuser/${id}`).then((response) => {
      setTransacoes(response.data);
    });
    api.get(`/lancamento/listByUser/${id}`).then((response) => {
      setLancamentos(response.data);
    });
  }, [atualizar]);
  function closeAlert() {
    setIsOpenModal(false);
    setTransactionFocus({
      status: false,
      _id: "",
      titulo: "",
      data: 12123213,
      dataPadrao: "",
      valor: 0,
      valorPadrao: 0,
      lancamento: "",
      cliente: "",
      createdAt: "",
      clienteNome: "",
      tipo: "",
    });
    setIsOpenAlert(false);
    setAtualizar(atualizar + 1);
  }
  function enviarPagamento(dados) {
    api
      .post("recebimento/registrar", dados)
      .then((response) => {
        console.log(response.data);
        setIsOpenAlert(true);
      })
      .catch((err) => {
        console.log(err);
        setDangerMsg("Ocorreu um erro tente novamente.");
        setIsOpenAlertDanger(true);
        if (err.response.status === 401) {
          logout();
        }
      });
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h3>
          Dados do cliente{" "}
          <Button
            component={Link}
            to={`/cliente/${cliente?._id}`}
            className="MuiButtonBase-root MuiButton-root MuiButton-text actionBtn bg-primary "
            >
              <i className="fa fa-pencil"></i>
          </Button>
        </h3>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={3}>
              <strong>Nome:</strong>
              <br /> {cliente?.nome}
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <strong>cpf:</strong>
              <br /> {cliente?.cpf}
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <strong>Telefone:</strong> <br /> {cliente?.telefone[0]}
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <strong>Email:</strong>
              <br /> {cliente?.email}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <strong>Endereço:</strong>
              <br /> Rua {cliente?.enderecoRua} n {cliente?.enderecoNumero},{" "}
              {cliente?.enderecoCep}, {cliente?.enderecoCidade}/
              {cliente?.enderecoEstado}
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <strong>Empréstimos:</strong>
              <br />
              {lancamentos.length}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <h3>Transações do cliente</h3>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card>
              <Grid className="tableResponsive">
                <table className="tableWrapper">
                  <thead className="tableHead">
                    <tr className="tableRow">
                      <th>Situação</th>
                      <th>Parcela</th>
                      <th>Data</th>
                      <th>Cliente</th>
                      <th>Valor</th>
                      <th>Valor recebido</th>
                      <th>Ação</th>
                    </tr>
                  </thead>
                  <tbody className="tableBody">
                    {transacoes.map((item, i) => (
                      <tr className="tableRow" key={i}>
                        <td style={{ fontSize: "1.7rem", textAlign: "center" }}>
                          {new Date(item.data).getTime() <
                            new Date(Date.now()).getTime() &&
                            item.status !== true && (
                              <p style={{ color: "#f46e6e" }}>
                                <AiFillCloseCircle />
                              </p>
                            )}
                          {new Date(item.data).getTime() >=
                            new Date(Date.now()).getTime() &&
                            item.status !== true && (
                              <p style={{ color: "#FFB95B" }}>
                                <MdSchedule />
                              </p>
                            )}
                          {item.status === true && (
                            <p style={{ color: "#4AD991" }}>
                              <AiFillCheckCircle />
                            </p>
                          )}
                        </td>

                        <td>{item.titulo}</td>
                        <td>{dateBr(item.data)}</td>
                        <td>{item.clienteNome}</td>
                        {/* <td>{item.observacao}</td> */}
                        <td>{currency(item.valor)}</td>
                        <td>{currency(item.pago)}</td>
                        <td>
                          <Grid className="actions">
                            <Button
                              className="actionBtn badge-success"
                              onClick={() => {
                                setIsOpenModal(true);
                                setTransactionFocus({
                                  status: item.status,
                                  _id: item._id,
                                  titulo: item.titulo,
                                  data: item.data,
                                  dataPadrao: item.dataPadrao,
                                  valor: item.valor,
                                  valorPadrao: item.valorPadrao,
                                  lancamento: item.lancamento,
                                  cliente: item.cliente,
                                  createdAt: item.createdAt,
                                  clienteNome: item.clienteNome,
                                  tipo: item.tipo,
                                  valorApagar: item.valorApagar,
                                  obs: item.obs,
                                });
                              }}
                            >
                              <i className="fa fa-eye"></i>
                            </Button>
                          </Grid>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card title="Resumo do mês">
              <ResumoTransacoes transacoes={transacoes} />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <h3>Empréstimos do cliente</h3>
          </Grid>
          {lancamentos.map((lancamento, key) => (
            <Grid item xs={12} sm={6} md={4} key={key}>
              <Card>
                <h4>{"Lancamento " + (Number(key) + 1)}</h4>
                <strong>Valor:</strong> {currency(lancamento.valor)}
                <br />
                <strong>Data:</strong> {dateBr(lancamento.dataLancamento)}
                <br />
                <strong>Parcelas:</strong> {lancamento.parcelas}
                <br />
                <strong>Cliente:</strong> {lancamento.clienteNome}
                <br />
                <Button
                  component={Link}
                  to={`/lancamento/${lancamento._id}`}
                  variant="contained"
                  fullWidth
                  color="primary"
                  style={{ marginTop: " 1rem", textTransform: "unset" }}
                >
                  {" "}
                  Detalhar empréstimo
                </Button>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Snackbar open={isOpenAlert} autoHideDuration={800} onClose={closeAlert}>
        <Alert onClose={closeAlert} severity="success">
          Pagamento registrado com sucesso!
        </Alert>
      </Snackbar>
      <Snackbar
        open={isOpenAlertDanger}
        autoHideDuration={1000}
        onClose={() => setIsOpenAlertDanger(false)}
      >
        <Alert onClose={() => setIsOpenAlertDanger(false)} severity="error">
          {dangerMsg}
        </Alert>
      </Snackbar>
      <DefaultModal
        button="modal"
        maxWidth="lg"
        buttonClass="actionBtn badge-success"
        transaction={transactionFocus}
        close={() => setIsOpenModal(false)}
        isOpen={isOpenModal}
        handleEnviarPagamento={enviarPagamento}
      />
    </Grid>
  );
}
