/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from "react";

import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { getUser, logout } from "../../services/auth";
import SweetAlertSingle from "./alert";
import { renderToStaticMarkup } from "react-dom/server";

import Card from "../../components/Card/index.js";
import {
  Grid,
  TextField,
  OutlinedInput,
  FormControl,
  Select,
  Button,
  Box,
} from "@material-ui/core";
import "./style.scss";
import api from "../../services/axios";
import "react-dropzone-uploader/dist/styles.css";
import "./style.scss";

import { FaTrashAlt } from "react-icons/fa";

import { dateBr, dateToInput } from "../../intl";
import Loans from "../../components/Loans";
import { Link } from "react-router-dom";
import { DatePicker } from "@material-ui/pickers";
export function NewLauncherForm() {
  const oneDay = 1000 * 60 * 60 * 24;
  const [success, setSuccess] = useState(false);

  const [clientes, setClientes] = useState([]);
  const [clienteId, setClienteId] = useState({ nome: "", id: "" });
  const [lancamento, setLancamento] = useState({
    valorTotal: 0,
    retornoLucro: 0,
    transacoes: [],
  });

  const [valor, setValor] = useState(0);
  const [mostrarAnexo, setMostrarAnexo] = useState(false);
  const [taxaJuros, setTaxaJuros] = useState(0);
  const [parcelasQtd, setParcelasQtd] = useState(1);
  const [dataPrimeiraParcela, setDataPrimeiraParcela] = useState("");
  const [dataemprestimo, setDataemprestimo] = useState(
    dateToInput(dateBr(Date.now()))
  );
  const [intervaloDias, setIntervaloDias] = useState(30);
  const [modeloCobranca, setModeloCobranca] = useState("");
  const [observacao, setObservacao] = useState("");
  const [valorErro, setValorErro] = useState();
  const [taxaJurosErro, setTaxaJurosErro] = useState();
  const [parcelasQtdErro, setParcelasQtdErro] = useState();
  const [dataPrimeiraParcelaErro, setDataPrimeiraParcelaErro] = useState();
  const [dataemprestimoErro, setDataemprestimoErro] = useState();
  const [intervaloDiasErro, setIntervaloDiasErro] = useState();
  const [modeloCobrancaErro, setModeloCobrancaErro] = useState();
  const [clienteErro, setClienteErro] = useState("");

  function verificarFormulario() {
    let erro;
    if ((modeloCobranca === "") | !modeloCobranca) {
      setModeloCobrancaErro("Selecione um modelo de cobrança");
    } else {
      setModeloCobrancaErro("");
    }
    if ((valor === 0) | !valor) {
      setValorErro("Este campo é obrigatório.");
      erro = true;
    } else {
      setValorErro("");
    }
    if ((taxaJuros === 0) | !taxaJuros) {
      setTaxaJurosErro("Este campo é obrigatório.");
      erro = true;
    } else {
      setTaxaJurosErro("");
    }
    if ((parcelasQtd === 0) | !parcelasQtd) {
      setParcelasQtdErro("Este campo é obrigatório.");
      erro = true;
    } else {
      setParcelasQtdErro("");
    }
    if ((dataemprestimo === "") | !dataemprestimo) {
      setDataemprestimoErro("Este campo é obrigatório.");
      erro = true;
    } else {
      setDataemprestimoErro("");
    }
    if ((dataPrimeiraParcela === 0) | !dataPrimeiraParcela) {
      setDataPrimeiraParcelaErro("Este campo é obrigatório.");
      erro = true;
    } else {
      setDataPrimeiraParcelaErro("");
    }
    if ((intervaloDias === 0) | !intervaloDias) {
      setIntervaloDiasErro("Este campo é obrigatório.");
      erro = true;
    } else {
      setIntervaloDiasErro("");
    }

    return erro;
  }

  function gerarPersonalizada() {
    let newTransactions = lancamento.transacoes;
    let newTotal = 0;
    let newRendimentos = 0;
    newTransactions.push({
      tipo: "4",
      titulo: `Parcela ${
        lancamento.transacoes.length === 0
          ? 1
          : lancamento.transacoes.length + 1
      }`,
      data:
        dateToInput(
          new Intl.DateTimeFormat("pt-BR").format(new Date(Date.now()))
        ) + " GMT-300",
      dataPadrao:
        dateToInput(
          new Intl.DateTimeFormat("pt-BR").format(new Date(Date.now()))
        ) + " GMT-300",
      valor: 0,
      valorPadrao: 0,
      valorApagar: 0,
      obs: "",
    });
    // newTotal =
    //   i + 1 === Number(parcelasQtd)
    //     ? newTotal + (juros * Number(valor) + Number(valor))
    //     : newTotal + juros * Number(valor);

    // newRendimentos = newRendimentos + juros * Number(valor);se
    setParcelasQtd(newTransactions.length);
    setDataPrimeiraParcela(newTransactions[0].data + " GMT-300");
    setLancamento({
      valorTotal: newTotal,
      retornoLucro: newRendimentos,
      transacoes: newTransactions,
    });
  }
  function gerarCheque() {
    let newTransactions = lancamento.transacoes;
    let newTotal = 0;
    let newRendimentos = 0;
    if (taxaJuros === 0) {
      setTaxaJurosErro("Digite o valor do juros");
    } else {
      setTaxaJurosErro("");
      newTransactions.push({
        tipo: "3",
        titulo: `cheque ${
          lancamento.transacoes.length === 0
            ? 1
            : lancamento.transacoes.length + 1
        }`,
        data:
          dateToInput(
            new Intl.DateTimeFormat("pt-BR").format(new Date(Date.now()))
          ) + " GMT-300",
        dataPadrao:
          dateToInput(
            new Intl.DateTimeFormat("pt-BR").format(new Date(Date.now()))
          ) + " GMT-300",
        valor: 0,
        valorPadrao: 0,
        valorApagar: 0,
        obs: "",
      });
      // newTotal =
      //   i + 1 === Number(parcelasQtd)
      //     ? newTotal + (juros * Number(valor) + Number(valor))
      //     : newTotal + juros * Number(valor);

      // newRendimentos = newRendimentos + juros * Number(valor);se
      setParcelasQtd(newTransactions.length);
      setDataPrimeiraParcela(newTransactions[0].data + " GMT-300");
      setLancamento({
        valorTotal: newTotal,
        retornoLucro: newRendimentos,
        transacoes: newTransactions,
      });
    }
  }

  function gerarParcela() {
    if (modeloCobranca === "3") {
      gerarCheque();
    }
    if (modeloCobranca === "4") {
      gerarPersonalizada();
    }
  }

  function atualizarCheques(event) {
    if (modeloCobranca === "3") {
      let newTransactions = lancamento.transacoes;

      let newTotal = 0;
      let newPago = 0;
      let valorCheque;
      let valorApagar;
      let jurosDia = Number(event) / 30 / 100;
      let diasDeEmprestimo;

      for (let i in newTransactions) {
        diasDeEmprestimo =
          (new Date(newTransactions[i].data).getTime() -
            new Date(dataemprestimo + "GMT-300").getTime()) /
          oneDay;
        valorCheque = newTransactions[i].valor;

        valorApagar = valorCheque - jurosDia * diasDeEmprestimo * valorCheque;
        newTransactions[i].valorApagar = valorApagar;

        newTotal = newTotal + newTransactions[i].valor;
        newPago = newPago + newTransactions[i].valorApagar;
      }

      setLancamento({
        valorTotal: newTotal,
        retornoLucro: newTotal - newPago,
        transacoes: newTransactions,
      });
      setValor(newPago);
    }
  }

  function logicaModeloCobancaUm(timestampPrimeiraParcela, juros) {
    let newTransactions = [];
    let newTotal = 0;
    let newRendimentos = 0;
    for (let i = 0; i < parcelasQtd; i++) {
      newTransactions.push({
        tipo: "1",
        titulo: `p. ${String(i + 1).padStart(2, "0")}`,
        data:
          dateToInput(
            new Intl.DateTimeFormat("pt-BR").format(
              new Date(timestampPrimeiraParcela + i * intervaloDias * oneDay)
            )
          ) + " GMT-300",
        dataPadrao:
          dateToInput(
            new Intl.DateTimeFormat("pt-BR").format(
              new Date(timestampPrimeiraParcela + i * intervaloDias * oneDay)
            )
          ) + " GMT-300",
        valor:
          i + 1 === Number(parcelasQtd)
            ? Number(juros * Number(valor)).toFixed(2)
            : (juros * Number(valor)).toFixed(2),
        valorPadrao:
          i + 1 === Number(parcelasQtd)
            ? Number(juros * Number(valor)).toFixed(2)
            : (juros * Number(valor)).toFixed(2),
      });
      newTotal =
        i + 1 === Number(parcelasQtd)
          ? newTotal + (juros * Number(valor) + Number(valor))
          : newTotal + juros * Number(valor);

      newRendimentos = newRendimentos + juros * Number(valor);
    }
    newTransactions.push({
      tipo: "2",
      titulo: `Capital`,
      data:
        dateToInput(
          new Intl.DateTimeFormat("pt-BR").format(
            new Date(
              timestampPrimeiraParcela +
                (Number(parcelasQtd) - 1) * intervaloDias * oneDay
            )
          )
        ) + " GMT-300",
      dataPadrao:
        dateToInput(
          new Intl.DateTimeFormat("pt-BR").format(
            new Date(
              timestampPrimeiraParcela +
                (Number(parcelasQtd) - 1) * intervaloDias * oneDay
            )
          )
        ) + " GMT-300",
      valor: Number(valor),
      valorPadrao: Number(valor),
    });
    setLancamento({
      valorTotal: newTotal,
      retornoLucro: newRendimentos,
      transacoes: newTransactions,
    });
  }

  function logicaModeloCobrancaDois(timestampPrimeiraParcela, juros) {
    let newTransactions = [];
    let newTotal = 0;
    let newRendimentos = 0;
    let parcelaCapital = Number(valor) / Number(parcelasQtd);
    for (let i = 0; i < parcelasQtd; i++) {
      newTransactions.push({
        tipo: "1",
        titulo: `p. ${String(i + 1).padStart(2, "0")}`,
        data:
          dateToInput(
            new Intl.DateTimeFormat("pt-BR").format(
              new Date(
                Number(timestampPrimeiraParcela) + i * intervaloDias * oneDay
              )
            )
          ) + " GMT-300",
        dataPadrao:
          dateToInput(
            new Intl.DateTimeFormat("pt-BR").format(
              new Date(timestampPrimeiraParcela + i * intervaloDias * oneDay)
            )
          ) + " GMT-300",
        valor: (
          parcelaCapital +
          juros * (Number(valor) - i * parcelaCapital)
        ).toFixed(2),
        valorPadrao: (
          parcelaCapital +
          juros * (Number(valor) - i * parcelaCapital)
        ).toFixed(2),
      });

      newTotal =
        newTotal +
        parcelaCapital +
        juros * (Number(valor) - i * parcelaCapital);
      newRendimentos =
        newRendimentos + juros * (Number(valor) - i * parcelaCapital);
    }

    setLancamento({
      valorTotal: newTotal,
      retornoLucro: newRendimentos,
      transacoes: newTransactions,
    });
  }
  function gerarTrasnacoes() {
    let erro = verificarFormulario();

    setLancamento({
      valorTotal: 0,
      retornoLucro: 0,
      transacoes: [],
    });

    if (!erro) {
      setTimeout(() => {
        let timestampPrimeiraParcela =
          new Date(dataPrimeiraParcela).getTime() + 1000 * 60 * 60 * 3; //3horas;
        let juros = ((Number(taxaJuros) / 30) * Number(intervaloDias)) / 100;

        if (modeloCobranca === "1") {
          logicaModeloCobancaUm(timestampPrimeiraParcela, juros, oneDay);
          setMostrarAnexo(true);
        }
        if (modeloCobranca === "2") {
          logicaModeloCobrancaDois(timestampPrimeiraParcela, juros, oneDay);
          setMostrarAnexo(true);
        }
        window.scrollTo({ top: 10000, behavior: "smooth" });
      }, 500);
    }
  }

  useEffect(() => {
    let token = getUser().token;
    api.defaults.headers.authorization = `Bearer ${token}`;
    api
      .get("cadastro/list")
      .then((response) => {
        setClientes(response.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.status === 401) {
            logout();
          }
        }
      });
  }, []);

  function removeCheque(key) {
    let newTransactions = lancamento.transacoes;
    setLancamento({
      valorTotal: 0,
      retornoLucro: 0,
      transacoes: [],
    });
    let newTotal = 0;
    let newPago = 0;
    let x = window.confirm(
      `tem certeza que deseja removar ess${
        modeloCobranca === "3" ? "e cheque" : "a parecela"
      }?`
    );
    if (x) {
      newTransactions.splice(key, 1);
    }

    setTimeout(() => {
      for (let i of newTransactions) {
        newTotal = newTotal + i.valor;
        newPago = newPago + i.valorApagar;
      }
      setLancamento({
        valorTotal: newTotal,
        retornoLucro: newTotal - newPago,
        transacoes: newTransactions,
      });

      setValor(newPago);
    }, 100);
  }

  function handleTransactionsValue(event, key) {
    let newTransactions = lancamento.transacoes;

    newTransactions[key].valor = Number(event);
    let newTotal = 0;
    for (let i of newTransactions) {
      newTotal = newTotal + i.valor;
    }

    setLancamento({
      valorTotal: newTotal,
      retornoLucro: newTotal - valor,
      transacoes: newTransactions,
    });
  }
  function handleTransactionsChequeValue(event, key) {
    let newTransactions = lancamento.transacoes;
    newTransactions[key].valor = Number(event);
    newTransactions[key].valorPadrao = Number(event);
    let newTotal = 0;
    let newPago = 0;

    if (modeloCobranca === "3") {
      let jurosDia = taxaJuros / 30 / 100;
      let diasDeEmprestimo =
        (new Date(newTransactions[key].data.split(" GMT-300")[0]).getTime() -
          new Date(dataemprestimo).getTime() +
          1000 * 60 * 60 * 3) /
        oneDay;
      console.log(newTransactions[key]);
      let valorCheque = Number(event);

      let valorApagar =
        valorCheque - jurosDia * Math.trunc(diasDeEmprestimo) * valorCheque;
      newTransactions[key].valorApagar = valorApagar;
    }

    for (let i of newTransactions) {
      newTotal = newTotal + i.valor;
      newPago = newPago + i.valorApagar;
    }

    setLancamento({
      valorTotal: newTotal,
      retornoLucro: newTotal - newPago,
      transacoes: newTransactions,
    });
    setValor(newPago);
  }
  function handleTransactionsChequeValorASerPago(event, key) {
    let newTransactions = lancamento.transacoes;
    let newTotal = 0;
    let newPago = 0;



    newTransactions[key].valorApagar = Number(event);

    for (let i of newTransactions) {
      newTotal = newTotal + i.valor;
      newPago = newPago + i.valorApagar;
    }

    setLancamento({
      valorTotal: newTotal,
      retornoLucro: newTotal - newPago,
      transacoes: newTransactions,
    });
    setValor(newPago);
  }

  function handleTransactionsDate(event, key) {
    let newTransactions = lancamento.transacoes;
    // let data = new Date(event).getTime();
    newTransactions[key].data = event + " GMT-300";
    setLancamento({
      valorTotal: lancamento.valorTotal,
      retornoLucro: lancamento.retornoLucro,
      transacoes: newTransactions,
    });
  }

  function handleTransactionsobs(event, key) {
    let newTransactions = lancamento.transacoes;
    // let data = new Date(event).getTime();
    newTransactions[key].obs = event;
    setLancamento({
      valorTotal: lancamento.valorTotal,
      retornoLucro: lancamento.retornoLucro,
      transacoes: newTransactions,
    });
  }
  function handleTransactionsChequeDate(event, key) {
    let newTransactions = lancamento.transacoes;
    let newTotal = 0;
    let newPago = 0;

    // let data = new Date(event).getTime();
    newTransactions[key].data = event + " GMT-300";
    newTransactions[key].dataPadrao = event + " GMT-300";
    if (modeloCobranca === "3") {
      let jurosDia = taxaJuros / 30 / 100;
      let diasDeEmprestimo =
        (new Date(event).getTime() +
          1000 * 60 * 60 * 3 -
          new Date(dataemprestimo).getTime() +
          1000 * 60 * 60 * 3) /
        oneDay;
      console.log(newTransactions[key]);
      let valorCheque = newTransactions[key].valor;

      let valorApagar =
        valorCheque - jurosDia * Math.trunc(diasDeEmprestimo) * valorCheque;
      newTransactions[key].valorApagar = valorApagar;
    }

    for (let i of newTransactions) {
      newTotal = newTotal + i.valor;
      newPago = newPago + i.valorApagar;
    }

    setLancamento({
      valorTotal: newTotal,
      retornoLucro: newTotal - newPago,
      transacoes: newTransactions,
    });
    setValor(newPago);
  }

  // console.log(lancamento)

  function sendForm() {
    for(let i of lancamento.transacoes){
      if(i.valor === 0){
        alert(`preencha o campo de valor da ${i.titulo}`)
        return
      }
    }
    if (clienteId.id === "") {
      setClienteErro("Selecione um Cliente!");
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      setClienteErro("");
      let token = getUser().token;
    api.defaults.headers.authorization = `Bearer ${token}`;
    api
      .post("lancamento/register", {
        ...lancamento,
        modeloCobranca,
        parcelas: Number(parcelasQtd),
        dataLancamento: dataemprestimo + " GMT-300",
        dataPrimeiraParcela: dataPrimeiraParcela + " GMT-300",
        taxaJuro: Number(taxaJuros),
        intervaloDias: Number(intervaloDias),
        cliente: clienteId.id,
        cheque: [],
        anexoGarantia: [],
        observacao,
        valor,
        capital: valor,
      })
      .then((response) => {
        setSuccess(true);
        setValor(0);
        setTaxaJuros(0);
        setParcelasQtd(0);
        setDataPrimeiraParcela("");
        setDataemprestimo("");
        setIntervaloDias(0);
        setModeloCobranca("");
        setObservacao("");
        let inputs = document.querySelectorAll("input");
        for (let i of inputs) {
          i.value = "";
        }
        let selects = document.querySelectorAll("select");
        for (let i of selects) {
          i.value = "";
        }
        setMostrarAnexo(false);
        setLancamento({
          valorTotal: 0,
          retornoLucro: 0,
          transacoes: [],
        });
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.status === 401) {
            logout();
          }
        }
      });
    }
    
  }

  return (
    // <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card className="editProfile">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <FormControl fullWidth className="textField" variant="outlined">
                <Select
                  native
                  name="estado"
                  variant="outlined"
                  input={
                    <OutlinedInput name="age" id="outlined-age-native-simple" />
                  }
                  onChange={(event) => {
                    setClienteId({
                      id: event.target.value.split("->")[0],
                      nome: event.target.value.split("->")[1],
                    });
                    clienteId.id !== "" && setClienteErro("");
                  }}
                  error={clienteErro ? true : false}
                  helperText={clienteErro}
                >
                  <option value="">Selecione um cliente</option>
                  {clientes.map((cliente) => (
                    <option
                      value={`${cliente._id}->${cliente.nome}`}
                      key={cliente._id}
                    >
                      {cliente.nome}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                component={Link}
                to="/adicionar-cliente"
                variant="contained"
                color="primary"
                fullWidth
                style={{ height: "100%" }}
              >
                Adicionar cliente
              </Button>
            </Grid>
          </Grid>
          {/* <label htmlFor="first_name">Cliente</label> */}
        </Card>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Card className="editProfile">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <FormControl fullWidth className="textField" variant="outlined">
                <label htmlFor="first_name">Modelo de cobrança</label>
                <Select
                  native
                  name="estado"
                  variant="outlined"
                  input={
                    <OutlinedInput name="age" id="outlined-age-native-simple" />
                  }
                  onChange={(event) => {
                    setModeloCobranca(event.target.value);
                    setMostrarAnexo(false);
                  }}
                  error={modeloCobrancaErro ? true : false}
                  helperText={modeloCobrancaErro}
                >
                  <option value="">Selecione</option>
                  <option value="1">
                    Juros em parcelas e capital na última parcela
                  </option>
                  <option value="2">Juros em parcelas e capital diluído</option>
                  <option value="3">Troca de cheque</option>
                  <option value="4">Personalizado</option>
                </Select>
              </FormControl>
            </Grid>
            {modeloCobranca !== "4" && (
              <Grid item sm={6} xs={6}>
                <label htmlFor="last_name">Taxa de juros (%/mês)</label>
                <CurrencyTextField
                  variant="outlined"
                  className="textField"
                  fullWidth
                  currencySymbol="%"
                  decimalPlaces={2}
                  decimalPlacesShownOnFocus={2}
                  decimalPlacesShownOnBlur={2}
                  minimumValue="0.00"
                  maximumValue={100}
                  outputFormat="number"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  error={taxaJurosErro ? true : false}
                  helperText={taxaJurosErro}
                  onChange={(event) => {
                    setTaxaJuros(event.target.value.replace(/,/g, "."));
                    setMostrarAnexo(false);
                    atualizarCheques(event.target.value.replace(/,/g, "."));
                  }}
                />
              </Grid>
            )}
            <Grid item sm={6} xs={6}>
              <label htmlFor="last_name">Data do empréstimo</label>
              {/* <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label="Date picker dialog"
          format="MM/dd/yyyy"
          value={dataemprestimo}
          onChange={event => setDataemprestimo(event.target.value)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }} */}
              {/* /> */}
              <TextField
                type="date"
                name="dataemprestimo"
                variant="outlined"
                className="textField"
                fullWidth
                defaultValue={dateToInput(dateBr(Date.now()))}
                onChange={(event) => {
                  setDataemprestimo(event.target.value);
                  setMostrarAnexo(false);
                }}
                error={dataemprestimoErro ? true : false}
                helperText={dataemprestimoErro}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      {modeloCobranca === "1" || modeloCobranca === "2" ? (
        <Grid item xs={12}>
          <Card className="editProfile">
            <form>
              <Grid container spacing={3}>
                <Grid item sm={6} xs={6}>
                  <label htmlFor="last_name">Valor</label>
                  <CurrencyTextField
                    variant="outlined"
                    className="textField"
                    fullWidth
                    currencySymbol="R$"
                    minimumValue="0"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    error={valorErro ? true : false}
                    helperText={valorErro}
                    onChange={(event) => {
                      setValor(
                        event.target.value.replace(/\./g, "").replace(/,/g, ".")
                      );
                      setMostrarAnexo(false);
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={6}>
                  <label htmlFor="last_name">Parcelas</label>
                  <TextField
                    type="number"
                    name="email"
                    variant="outlined"
                    className="textField"
                    fullWidth
                    defaultValue={parcelasQtd}
                    onChange={(event) => {
                      setParcelasQtd(event.target.value);
                      setMostrarAnexo(false);
                    }}
                    error={parcelasQtdErro ? true : false}
                    helperText={parcelasQtdErro}
                  />
                </Grid>
                <Grid item sm={6} xs={6}>
                  <label htmlFor="last_name">Data do 1º pagamento</label>
                  <TextField
                    name="primeiro pagamento"
                    type="date"
                    variant="outlined"
                    className="textField"
                    fullWidth
                    // min={dataemprestimo}
                    defaultValue={dataPrimeiraParcela}
                    error={dataPrimeiraParcelaErro ? true : false}
                    helperText={dataPrimeiraParcelaErro}
                    onChange={(event) => {
                      setDataPrimeiraParcela(event.target.value);
                      setMostrarAnexo(false);
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={6}>
                  <label htmlFor="last_name">Intervalo de dias</label>
                  <TextField
                    name="diasintervalo"
                    type="number"
                    variant="outlined"
                    className="textField"
                    fullWidth
                    defaultValue={intervaloDias}
                    error={intervaloDiasErro ? true : false}
                    helperText={intervaloDiasErro}
                    onChange={(event) => {
                      setIntervaloDias(event.target.value);
                      setMostrarAnexo(false);
                    }}
                  />
                </Grid>
                {/* <Grid item sm={12} xs={12}>
                          <label htmlFor="last_name">Cheque</label>
                          <Grid container spacing={3}>
                            <Grid item sm={4} xs={6}>
                              <TextField
                                name="diasintervalo"
                                variant="outlined"
                                className="textField"
                                placeholder="data"
          
          
                                fullWidth
                                onChange={(event) => setIntervaloDias(event.target.value)}
                              />
                            </Grid>
                            <Grid item sm={8} xs={6}>
                              <TextField
                                name="diasintervalo"
                                variant="outlined"
                                className="textField"
                                placeholder="descrição"
          
                                fullWidth
                                onChange={(event) => setIntervaloDias(event.target.value)}
                              />
                            </Grid>
                          </Grid>
                        </Grid> */}
                <Grid item xs={12} sm={12} style={{ margin: "1rem 0" }}>
                  <Button
                    // color="green"
                    type="button"
                    onClick={gerarTrasnacoes}
                    variant="contained"
                    fullWidth
                    className="btn bg-primary"
                  >
                    Simular empréstimo
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Grid>
      ) : (
        <></>
      )}
      {modeloCobranca === "3" || modeloCobranca === "4" ? (
        <>
          <Grid item xs={12} sm={12}>
            <Card className="editProfile">
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <h4>
                  {modeloCobranca === "3" && "Cheques"}
                  {modeloCobranca === "4" && "Parcelas"}
                </h4>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={gerarParcela}
                >
                  +
                </Button>
              </Box>
              <Grid container spacing={4}>
                {lancamento.transacoes?.map((item, key) => (
                  <Grid item xs={12}>
                    <Grid key={key} container spacing={2}>
                      {/*  ,{transacao.data}, <br/> */}
                      <Grid item md={2} xs={12}>
                        {modeloCobranca === "3" ? "cheque" : "parcela"}{" "}
                        {key + 1}{" "}
                        <span
                          className="remove-Icon"
                          onClick={() => removeCheque(key)}
                        >
                          <FaTrashAlt />
                        </span>
                      </Grid>
                      <Grid item md={3} xs={6}>
                        <label>Vencimento</label>
                        <DatePicker
                          format="DD/MM/yyyy"
                          minDate={new Date(dataemprestimo + "GMT-300")}
                          value={item.data.split(" GMT-300")[0]}
                          onChange={(date) =>
                            handleTransactionsChequeDate(
                              dateToInput(dateBr(date)),
                              key
                            )
                          }
                        />
                      </Grid>
                      <Grid item md={2} xs={6}>
                        <label htmlFor="">
                          {modeloCobranca === "3"
                            ? "Valor do cheque"
                            : "Valor da parcela"}
                        </label>
                        <CurrencyTextField
                          fullWidth
                          currencySymbol="R$"
                          minimumValue="0"
                          outputFormat="number"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          value={item.valor}
                          onChange={(event) =>
                            handleTransactionsChequeValue(
                              event.target.value
                                .replace(/\./g, "")
                                .replace(/,/g, "."),
                              key
                            )
                          }
                        />
                      </Grid>
                      <Grid item md={2} xs={6}>
                        <label htmlFor="">Valor a ser pago</label>

                        <CurrencyTextField
                          fullWidth
                          currencySymbol="R$"
                          minimumValue="0"
                          outputFormat="number"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          onChange={(event) =>
                            handleTransactionsChequeValorASerPago(
                              event.target.value
                                .replace(/\./g, "")
                                .replace(/,/g, "."),
                              key
                            )
                          }
                          value={item.valorApagar}
                        />
                      </Grid>
                      <Grid item md={3} xs={6}>
                        <label htmlFor="">Obs</label>
                        <TextField
                          name="primeiro pagamento"
                          className="textField"
                          fullWidth
                          value={item.obs}
                          onChange={(event) =>
                            handleTransactionsobs(event.target.value, key)
                          }
                          // defaultValue={transacao.data.split(" GMT-300")[0]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Card className="editProfile" title="Resumo">
              <Loans
                resumProp={{
                  valorTotal: lancamento.valorTotal,
                  jurosTaxa: taxaJuros,
                  lucro: lancamento.retornoLucro,
                  valorEmprestado: valor,
                }}
              />
              <Button
                type="button"
                onClick={sendForm}
                style={{ marginTop: "1rem" }}
                className="btn bg-success"
                fullWidth
              >
                Salvar
              </Button>
            </Card>
          </Grid>
        </>
      ) : (
        <></>
      )}
      {mostrarAnexo && (
        <>
          <Grid item xs={12} sm={12}>
            <Card className="editProfile">
              <Grid container spacing={4}>
                <Grid item sm={6} xs={12}>
                  <label htmlFor="last_name">Anexo de garantia</label>
                  <TextField
                    variant="outlined"
                    className="textField"
                    fullWidth
                    // onChange={(event) => setIntervaloDias(event.target.value)}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <label htmlFor="last_name">Observação</label>
                  <TextField
                    aria-label="empty textarea"
                    variant="outlined"
                    className="textField"
                    fullWidth
                    onChange={(event) => setObservacao(event.target.value)}
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card title="Transações" className="editProfile">
              <Box
                className="transactions-scroll"
                style={{
                  maxHeight: "300px",
                  overflowY: "auto",
                  overflowX: "hidden",
                  paddingRight: "1rem",
                }}
              >
                {lancamento.transacoes.map((transacao, key) => (
                  <Grid key={key} container spacing={2}>
                    {/*  ,{transacao.data}, <br/> */}
                    <Grid item sm={2} xs={2}>
                      {transacao.titulo}
                    </Grid>
                    <Grid item sm={5} xs={5}>
                      <TextField
                        name="primeiro pagamento"
                        type="date"
                        className="textField"
                        fullWidth
                        defaultValue={transacao.data.split(" GMT-300")[0]}
                        onChange={(event) =>
                          handleTransactionsDate(
                            event.target.value
                              .replace(/\./g, "")
                              .replace(/,/g, "."),
                            key
                          )
                        }
                      />
                    </Grid>
                    <Grid item sm={5} xs={5}>
                      <CurrencyTextField
                        fullWidth
                        currencySymbol="R$"
                        minimumValue="0"
                        outputFormat="number"
                        decimalCharacter=","
                        digitGroupSeparator="."
                        defaultValue={transacao.valor}
                        onChange={(event) =>
                          handleTransactionsValue(
                            event.target.value
                              .replace(/\./g, "")
                              .replace(/,/g, "."),
                            key
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                ))}
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card title="Resumo" className="editProfile">
              <Box className="">
                <Loans
                  resumProp={{
                    valorTotal: lancamento.valorTotal,
                    jurosTaxa: taxaJuros,
                    lucro: lancamento.retornoLucro,
                    valorEmprestado: valor,
                  }}
                />
              </Box>
              <Button
                type="button"
                onClick={sendForm}
                style={{ marginTop: "1rem" }}
                className="btn bg-success"
                fullWidth
              >
                Salvar
              </Button>
            </Card>
          </Grid>
        </>
      )}
      <SweetAlertSingle
        title="Operação concluída"
        type="success"
        show={success}
        success
        confirmButtonText="Emprestar"
        cancelButtonText="Consultar empréstimos"
        showCancelButton={true}
        onConfirm={() => setSuccess(false)}
        onCancel={() => (window.location.href = "/lancamentos")}
        text={renderToStaticMarkup(
          <span>
            Empréstimo lançado com sucesso para o cliente: <br />
            <strong>{clienteId.nome}</strong>
          </span>
        )}
        showLoaderOnConfirm={true}
      />
    </Grid>
    // </MuiPickersUtilsProvider>
  );
}
