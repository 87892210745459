import React, { useState } from "react";
import { Grid, Menu, Button, Hidden } from "@material-ui/core";

import "./style.scss";

// images
import avatar from "../../assets/images/avatar.jpg";

import fullScreen from "../../assets/images/icons/full-screen.svg";
import { getUser, logout } from "../../services/auth";
import { Link } from "react-router-dom";

const Header = (props) => {
  
  const [profile, setProfile] = React.useState(null);

  const profileOpen = (event) => {
    setProfile(event.currentTarget);
  };

  const profileClose = () => {
    setProfile(null);
  };

  function toggleFullScreen() {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||    
     (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {  
        document.documentElement.requestFullScreen();  
      } else if (document.documentElement.mozRequestFullScreen) {  
        document.documentElement.mozRequestFullScreen();  
      } else if (document.documentElement.webkitRequestFullScreen) {  
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);  
      }  
    } else {  
      if (document.cancelFullScreen) {  
        document.cancelFullScreen();  
      } else if (document.mozCancelFullScreen) {  
        document.mozCancelFullScreen();  
      } else if (document.webkitCancelFullScreen) {  
        document.webkitCancelFullScreen();  
      }  
    }  
  } 

  return (
    <header className="headerArea">
      <Grid container spacing={4} alignItems="center">
        <Hidden mdUp>
          <Grid item xs={2}>
            <ul className="headerLeft">
              <li onClick={props.colupsMenuHandler} className="menuTrigger">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fal"
                  data-icon="bars"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  className="svg-inline--fa fa-bars fa-w-14 fa-fw"
                >
                  <path
                    fill="currentColor"
                    d="M442 114H6a6 6 0 0 1-6-6V84a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6z"
                  ></path>
                </svg>
              </li>
            </ul>
          </Grid>
        </Hidden>
        <Grid item md={12} xs={10}>
          <ul className="headerRight">
            <li onClick={toggleFullScreen} className="fullScreenWrap">
              <img src={fullScreen} alt="" />
            </li>
            <div className="profileWrap">
              <span onClick={profileOpen} className="name">
                <span className="text">{getUser().user.name}</span>
                <i className="fa fa-angle-down" />
              </span>
              <Menu
                anchorEl={profile}
                keepMounted
                open={Boolean(profile)}
                onClose={profileClose}
                elevation={1}
                getContentAnchorEl={null}
                className="profileWrapper"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                classes={{
                  paper: "profilePaper",
                  list: "profileList",
                }}
              >
                <li>
                  <Button fullWidth component={Link} to="/redefinir-senha">Redefinir senha</Button>
                </li>
                <li>
                  <Button fullWidth component={Link} onClick={logout}>Sair</Button>
                </li>
                
              </Menu>
            </div>
          </ul>
        </Grid>
      </Grid>
    </header>
  );
};

export default Header;
