import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
// import messages from 'components/SidebarNav/messages';

// Private components
import PrivateRoute from '../_PrivateRoute';
import PublicRoute from '../_PublicRoute';

import {Login} from "../pages/Login/index.tsx"
import {Dashboard} from "../pages/Dashboard/index.jsx"
import {ProfilePage} from '../pages/NewClient/index.jsx';
import { InvoiceLists } from '../pages/Clientes';
import { EditClient } from '../pages/EditClient';
import { NewLauncher } from '../pages/NewLauncher';
import { Transactions } from '../pages/Transactions';
import NotfoundPage from '../components/NotfoundPage';
import { Lancamentos } from '../pages/Lancamentos';
import { LancamentoDetalhado } from '../pages/LancamentoDetalhado';
import { NewPassword } from '../pages/NewPassword';
import { ClienteView } from '../pages/ClienteView';
import { Wapi } from '../pages/Wapi';

const Routes = props => (
    <BrowserRouter>
    <Switch>
        {/* dashboard  start */}
        <PrivateRoute
            exact
            path="/dashboard"
            title="Dashboard > Campaign Monitoring"
            component={Dashboard}
        />
        <PrivateRoute
            exact
            path="/adicionar-cliente"
            title="adicionar cliente"
            component={ProfilePage}
        />
        <PrivateRoute
            exact
            path="/clientes"
            title="Lista de clientes"
            component={InvoiceLists}
        />
        <PrivateRoute
            exact
            path="/cliente/:id"
            title="Lista de clientes"
            component={EditClient}
        />
        <PrivateRoute
            exact
            path="/novo-lancamento"
            title="Lista de clientes"
            component={NewLauncher}
        />
        <PrivateRoute
            exact
            path="/transacoes"
            title="Transações"
            component={Transactions}
        />
        <PrivateRoute
            exact
            path="/lancamentos"
            title="Lancamentos"
            component={Lancamentos}
        />
        <PrivateRoute
            exact
            path="/lancamento/:id"
            title="Lancamentos"
            component={LancamentoDetalhado}
        />
        <PrivateRoute
            exact
            path="/redefinir-senha/"
            title="redefinir senha"
            component={NewPassword}
        />
        <PrivateRoute
            exact
            path="/vizualizar-cliente/:id"
            title="Vizualizar cliente"
            component={ClienteView}
        />
        <PrivateRoute
            exact
            path="/whatsapp/api"
            title="Configurar WhatsApp"
            component={Wapi}
        />

        {/* public route components  */}
        <PublicRoute exact path="/" component={Login} />
        {/* <Route component={<>não encontrado</>} /> */}
        <Route path='*' exact={true} component={NotfoundPage} />

    </Switch>
    </BrowserRouter>
);
export default Routes;
