import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { currency, dateBr, dateTimeBr, dateToInput } from "../../intl";

import {
  IoCalendarOutline,
  IoPersonOutline,
  IoSwapHorizontal,
} from "react-icons/io5";
import { FiDollarSign } from "react-icons/fi";
import "./styles.scss";
import api from "../../services/axios";
import { getUser, logout } from "../../services/auth";
import { Link } from "react-router-dom";

const DefaultModal = ({
  isOpen,
  close,
  transaction,
  handleEnviarPagamento,
  loading,
}) => {
  const oneDay = 1000 * 60 * 60 * 24;

  const [transacao, setTransacao] = useState(false);
  const [open, setOpen] = useState(false);
  const [editable, setEditable] = useState(true);
  const [logs, setLogs] = useState();
  const [lancamento, setLancamentos] = useState({});
  const [valorAserPago, setValorAserPago] = useState(0);
  const [taxaJuros, setTaxaJuros] = useState(0);
  const [dataPagamento, setDataPagamento] = useState("");
  const [liquidar, setLiquidar] = useState(false);
  const [recebimentos, setRecebimentos] = useState([]);

  const calcularPagamento = (juros, data) => {
    setValorAserPago(0);
    setTimeout(() => {
      let valorParaPagar;
      let dataInicial =
        new Date(transaction.data).getTime() -
        lancamento.intervaloDias * oneDay;
      let dataInicialCapital = new Date(transaction.data).getTime();

      let quantidadeDeDias =
        (new Date(data + " GMT-300").getTime() - dataInicial) / oneDay;

      let quantidadeDeDiasCapital =
        (new Date(data + " GMT-300").getTime() - dataInicialCapital) / oneDay;

      if (lancamento.modeloCobranca === "1") {
        if (transaction.tipo === "1") {
          valorParaPagar =
            ((quantidadeDeDias * juros) / lancamento.intervaloDias / 100) *
              Number(lancamento.capital) -
            recebimentos.reduce((a, b) => a + b, 0);
        } else if (transaction.tipo === "2") {
          valorParaPagar =
            ((quantidadeDeDiasCapital * juros) /
              lancamento.intervaloDias /
              100) *
              Number(lancamento.valor) +
            Number(lancamento.valor);
        }
      }
      if (lancamento.modeloCobranca === "3") {
        if (transaction.tipo === "3") {
          quantidadeDeDias =
            (new Date(data + " GMT-300") -
              new Date(lancamento.dataLancamento)) /
            oneDay;
          valorParaPagar =
            ((quantidadeDeDias * juros) / lancamento.intervaloDias / 100) *
              Number(transaction.valor) -
            recebimentos.reduce((a, b) => a + b, 0) +
            Number(transaction.valorApagar);
        }
      }
      if (lancamento.modeloCobranca === "2") {
        valorParaPagar =
          ((quantidadeDeDias * juros) / lancamento.intervaloDias / 100) *
            Number(lancamento.capital) +
          lancamento.valor / lancamento.parcelas -
          recebimentos.reduce((a, b) => a + b, 0);
      }

      if (valorParaPagar >= 0) {
        setValorAserPago(valorParaPagar.toFixed(2));
      } else {
        setValorAserPago(0);
      }
    }, 200);
  };

  const getLogs = useCallback(() => {
    let token = getUser().token;
    api.defaults.headers.authorization = `Bearer ${token}`;
    if (transaction._id) {
      api
        .get(`/logs/${transaction._id}`)
        .then((response) => {
          setLogs(response.data);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            logout();
          }
        });
    }

    api
      .get(`/lancamento/list/${transaction.lancamento}`)
      .then((response) => {
        setLancamentos(response.data);
        setTaxaJuros(response.data.taxaJuro);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          logout();
        }
      });
    api
      .get(`/recebimentos/lista/${transaction._id}`)
      .then((response) => {
        let newRecebimentos = [];
        for (let i of response.data) {
          newRecebimentos.push(i.valor);
        }
        setRecebimentos(newRecebimentos);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          logout();
        }
      });
    setTransacao(transaction);
    setValorAserPago(0);
    setEditable(transaction.status);
    setLiquidar(false);
  }, [transaction]);

  useEffect(() => {
    setOpen(isOpen);
    getLogs();
  }, [isOpen, getLogs]);

  function handleClose() {
    setOpen(false);
    close();
  }

  async function enviarPagamento() {
    let x = true
    if (liquidar && transaction.tipo === "2") {
      x = window.confirm("tem certeza que deseja liquidar o capital?");
    } else if(liquidar) {
      x = window.confirm("tem certeza que deseja liquidar o valor?");
    } else if(transaction.tipo === "2") {
      x = window.confirm("tem certeza que deseja receber o valor referente ao capital?");
    }
    if (x) {
      await handleEnviarPagamento({
        valor: valorAserPago,
        data: dataPagamento,
        cliente: transaction.cliente,
        lancamento: transaction.lancamento,
        transacao: transaction._id,
        liquidar,
        tipo: transaction.tipo,
      });
    }
  }

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        className="modalWrapper"
        maxWidth="md"
        // style={{overflow:"hidden"}}
      >
        <Grid
          container
          style={{ width: "100%", margin: "0", padding: "1rem" }}
          spacing={2}
        >
          <Grid item xs={12}>
            <Grid
              container
              className=""
              spacing={2}
              style={{ width: "100%", margin: "0" }}
            >
              <Grid item xs={12} sm={8}>
                <h3
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1.8rem",
                  }}
                >
                  <span
                    className="icon"
                    style={{
                      backgroundColor: "rgba(238, 130, 238, 0.20)",
                      color: "rgba(238, 130, 238)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "40px",
                      height: "40px",
                      borderRadius: "0.5rem",
                      fontSize: "1.3rem",
                      marginRight: "10px",
                    }}
                  >
                    <IoSwapHorizontal />
                  </span>
                  {transaction?.tipo === "1" &&
                    transaction.titulo.split(". ")[1] + " º Parcela"}
                  {transaction?.tipo === "2" && " Capital"}
                  {transaction?.tipo === "3" && transaction.titulo}
                  {transaction?.tipo === "4" && transaction.titulo}
                </h3>
              </Grid>
              <Grid item xs={12} sm={4} style={{ paddingTop: "1rem" }}>
                <Button
                  className=""
                  size="small"
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ height: "100%" }}
                  component={Link}
                  to={`/lancamento/${transaction.lancamento}`}
                  onClick={() => setOpen(false)}
                >
                  Ir para empréstimo
                </Button>{" "}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              style={{ width: "100%", margin: "0" }}
              className="transaction-resum-modal"
            >
              <Grid item xs={12} sm={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12} className="container-resum-title">
                    <strong className="title">
                      <span
                        className="icon"
                        style={{
                          backgroundColor: "rgba(241, 103, 44, 0.20)",
                          color: "rgba(241, 103, 44)",
                        }}
                      >
                        <IoPersonOutline />
                      </span>
                      Cliente:
                    </strong>
                  </Grid>
                  <Grid item xs={12}>
                    <p>{transaction.clienteNome}</p>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6} sm={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <strong className="title">
                      <span
                        className="icon"
                        style={{
                          backgroundColor: "rgba(238, 130, 238, 0.20)",
                          color: "rgba(238, 130, 238)",
                        }}
                      >
                        <IoCalendarOutline />
                      </span>
                      Data:
                    </strong>{" "}
                  </Grid>
                  <Grid item xs={12}>
                    <p>
                      {transaction.data &&
                        dateBr(new Date(transaction.data).getTime())}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <strong className="title">
                      <span
                        className="icon"
                        style={{
                          backgroundColor: "rgba(3, 174, 20, 0.2)",
                          color: "rgba(3, 174, 20)",
                        }}
                      >
                        <FiDollarSign />
                      </span>
                      Valor:
                    </strong>
                  </Grid>
                  <Grid item xs={12}>
                    <p>{currency(transaction.valor)}</p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                {transaction.obs}
              </Grid>

              <Grid item xs={12} sm={12}>
                <h3 style={{ marginBottom: "0.5rem" }}>Histórico</h3>
                <Grid
                  className="tableResponsive"
                  style={{ maxHeight: "30vh", overflowY: "auto" }}
                >
                  <table className="tableWrapper">
                    <thead>
                      <tr>
                        <th>Data</th>
                        {/* <th>Url</th> */}
                        <th>Detalhe</th>
                      </tr>
                    </thead>
                    <tbody>
                      {logs?.map((item, i) => (
                        <tr key={i}>
                          {/* <td><i className={`fa fa-${item.icon} ${item.color} mr-5`} /> {item.url} </td> */}
                          <td>{dateTimeBr(item.createdAt)}</td>
                          <td>{item.mensagem}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Grid>
              </Grid>
              {!editable && (
                <Grid item xs={12} sm={12}>
                  <h3>Registrar Recebimento</h3>
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={3}>
                      <label>Data do pagamento:</label>
                      <TextField
                        type="date"
                        onChange={(event) => {
                          setDataPagamento(event.target.value);
                          calcularPagamento(taxaJuros, event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <label>Data de vencimento:</label>
                      <TextField
                        // type="date"
                        value={dateBr(transaction.data)}
                        readyonly={true}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <label>taxa de juros:</label>
                      {taxaJuros > 0 && (
                        <TextField
                          // type="date"
                          defaultValue={taxaJuros}
                          onChange={(event) => {
                            setTaxaJuros(event.target.value);
                            calcularPagamento(
                              Number(event.target.value),
                              dataPagamento
                            );
                          }}
                        />
                      )}
                      {taxaJuros <= 0 && (
                        <TextField
                          type="number"
                          defaultValue={0}
                          onChange={(event) => {
                            setTaxaJuros(event.target.value);
                            calcularPagamento(
                              Number(event.target.value),
                              dataPagamento
                            );
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <label>Valor: {currency(valorAserPago)}</label>
                      <input
                        style={{
                          display: "block",
                          paddingTop: "0.5rem",
                          paddingBottom: "0.5rem",
                          border: "none",
                          borderBottom: "1px solid #000011",
                        }}
                        type="number"
                        value={valorAserPago}
                        onChange={(event) =>
                          setValorAserPago(event.target.value)
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={liquidar}
                            onChange={() => setLiquidar(!liquidar)}
                            value="checkedB"
                            classes={{
                              root: "checkboxWrap checkPrimary",
                              checked: "checked",
                            }}
                          />
                        }
                        label="Liquidar parcela"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              className=""
              style={{
                width: "100%",
                margin: "0",
                padding: "1rem",
                justifyContent: "space-between",
              }}
            >
              <Button
                className="btn bg-danger"
                variant="contained"
                onClick={handleClose}
              >
                Fechar
              </Button>
              {!editable && (
                <Button
                  loading={loading}
                  disabled={loading}
                  className="btn bg-success"
                  variant="contained"
                  onClick={enviarPagamento}
                >
                  Registrar pagamento
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </Fragment>
  );
};
export default DefaultModal;
