export const TOKEN_KEY = "@user-admin-syfactory";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getUser = () => JSON.parse(localStorage.getItem(TOKEN_KEY) as string);

export const login = (token:string) => {
  localStorage.setItem(TOKEN_KEY, token);
  window.location.reload()
};
export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    window.location.reload()
};
