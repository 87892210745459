export function currency(number) {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(number);
}

export function toNumber(string) {
  return Number(string.replace(/\./g, "").replace(/,/g, ".").split("R$ ")[0]);
}

export function dateToInput(date) {
  return `${date.split("/")[2]}-${date.split("/")[1]}-${date.split("/")[0]}`;
}

//timestamp
export function dateBr(date){
    return  new Intl.DateTimeFormat("pt-BR", { timeZone: "America/Sao_Paulo",
  }).format(new Date(date))
}

export function dateTimeBr(date){
    return new Intl.DateTimeFormat("pt-br", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
      timeZone: "America/Sao_Paulo",
    }).format(new Date(date))
}