import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";

import "./style.scss";
import { currency } from "../../intl";

export const ResumoTransacoes = ({ transacoes, dia, lancamento }) => {
  const [valores, setValores] = useState([]);
  const [valoresRecebido, setValoresRecebido] = useState([]);
  const [valoresVencido, setValoresVencido] = useState([]);

  useEffect(() => {
    let newValores = [];
    let newValoresRecebidos = [];
    let newValoresVencidos = [];
    console.log(transacoes);
    for (let i of transacoes) {
      if (!i.status) {
        newValores.push(i.valor);
      }
      newValoresRecebidos.push(i.pago);
      if (new Date(i.data).getTime() < Date.now() && !i.status) {
        newValoresVencidos.push(i.valor);
      }
    }

    // setResumo(transacoes)
    setValores(newValores);
    setValoresRecebido(newValoresRecebidos);
    setValoresVencido(newValoresVencidos);
  }, [transacoes]);

  const loans = [
    {
      title: `Total recebido `,
      amount: currency(valoresRecebido.reduce((a, b) => a + b, 0)),
      percentage: 1 * 100,
      color: "green",
    },
    {
      title: `Parcelas a receber (${valores.length})`,
      amount: currency(valores.reduce((a, b) => a + b, 0)),
      percentage: 10,
      color: "blue",
    },
    {
      title: `Parcelas vencidas (${valoresVencido.length})`,
      amount: currency(valoresVencido.reduce((a, b) => a + b, 0)),
      percentage: 10 * 100,
      color: "red",
    },
    {
      title: "Parcelas canceladas (0)",
      amount: currency(0),
      percentage: 100,
      color: "gray",
    },
  ];

  if (lancamento) {
    loans.push({
      title: `Total recebido de juros `,
      amount: currency(
        valoresRecebido.reduce((a, b) => a + b, 0) - lancamento?.valor
      ),
      percentage: 1 * 100,
      color: "green",
    });
  }
  if (dia) {
    loans.push({
      title: "Total para " + dia.type,
      amount: currency(dia.valor),
      percentage: 100,
      color: "gray",
    });
  }
  return (
    <Grid className="loansWrapper">
      <Grid container spacing={3}>
        {loans.map((item, i) => {
          return (
            <Grid key={i} item sm={12} xs={12}>
              <Grid className="loanCard">
                <h4>{item.title}</h4>
                <Grid className="lbtm">
                  <h3>{item.amount}</h3>
                  <Grid className="lprogressbar">
                    <span className="prbg" style={{ background: item.color }} />
                    {/* <span
                      className="lpbar"
                      style={{
                        width: `${item.percentage}%`,
                        background: item.color,
                      }}
                    /> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
