import { Button, Grid, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useState } from "react";
import Card from "../../components/Card";
import { getUser } from "../../services/auth";
import api from "../../services/axios";

import{BiError} from "react-icons/bi"

export function NewPassword() {
  const [newpassword, setNewpassword] = useState("");
  const [oldpassword, setOldPassword] = useState("");
  const [error, setError] = useState("");
  const [valido, setValido] = useState("");

  function sendNewPass(e) {
    e.preventDefault();
    let token = getUser().token;
    let id = getUser().user._id;
    api.defaults.headers.authorization = `Bearer ${token}`;
    api
      .put(`/user/updatePassword/${id}`, { oldpassword, newpassword })
      .then((response) => {
        setOldPassword("");
        setNewpassword("");
        setValido("Senha alterada com sucesso!")
        setError("")

      })
      .catch((err) => {console.log(err.response.data.error)
        setError(err.response.data.error)
      });
  }

  return (
    <Card title="Redefinir senha">
      <form onSubmit={sendNewPass}>
        {
          error && <Alert color="error" icon={<BiError/>}>{error}</Alert>
        }
        
        {
          valido && <Alert >{valido}</Alert>
        }
        
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <label>Digite sua senha atual:</label>
            <TextField
              fullWidth
              type="password"
              variant="outlined"
              value={oldpassword}
              onChange={(event) => setOldPassword(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <label>Digite sua nova senha:</label>
            <TextField
              fullWidth
              type="password"
              variant="outlined"
              value={newpassword}
              onChange={(event) => setNewpassword(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Salvar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
}
