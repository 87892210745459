import React, { useState, Fragment } from "react";
import { Grid, ExpansionPanel, ExpansionPanelSummary } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./style.scss";

import {IoStatsChartSharp} from "react-icons/io5"
import {FaUsers, FaWhatsapp} from "react-icons/fa"
import {RiExchangeDollarLine} from "react-icons/ri"
import {AiOutlineRise} from "react-icons/ai"
import {FcPlus} from "react-icons/fc"

// images


import sidenavBg from "../../assets/images/sidenav-bg.jpg";

const SidebarNav = (props) => {
  const navigations = [
    {
      name: `Dashboard`,
      id: 11031,
      // alwaysexpand: true,
      menus: [
        {
          name: `Emprestar`,
          link: "/novo-lancamento",
          id: 5968568,
          icon: <FcPlus/>
        },
        {
          name: `Dashboard`,
          link: "/dashboard",
          id: 5968568,
          icon: <IoStatsChartSharp/>
        },
      ],
    },
    {
      name: "Clientes",
      // alwaysexpand: true,
      id: 88854,
      menus: [
        {
          name: `Clientes`,
          link: "/clientes",
          id: 59681445745,
          icon: <FaUsers/>

        },
        // {
        //   name: `Lista de clientes inadiplentes`,
        //   link: "/contact-list",
        //   id: 181058459,
        //   color:
        //     "linear-gradient(rgba(255, 209, 238, 0.5) -35.41%, rgba(255, 107, 175, 0.5) 84.45%)",
        //   value: "75",
        // },
      ],
    },
    {
      name: `Lançamentos`,
      // alwaysexpand: true,
      id: 78454565,
      menus: [
        {
          name: "Transações",
          link: "/transacoes",
          id: 59681445745,
          // color:
          // "linear-gradient(rgba(255, 209, 238, 0.5) -35.41%, rgba(255, 107, 175, 0.5) 84.45%)",
          // value: "75",
          icon: <RiExchangeDollarLine/>

        },
        {
          name: "Empréstimos",
          link: "/lancamentos",
          id: 181058459,
          icon: <AiOutlineRise/>

        },
        {
          name: "API WhatsApp",
          link: "/whatsapp/api",
          id: 181058459,
          icon: <FaWhatsapp/>

        },
      ],
    },
  ];

  const [parent_expanded, setParentExpanded] = useState("1");
  const parent_handleChange = (panel) => (event, new_parent_expanded) => {
    setParentExpanded(new_parent_expanded ? panel : false);
    setExpanded(false);
  };

  const [expanded, setExpanded] = useState("1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Grid className="sidebarMainWrapper">
      <div onClick={props.colupsMenuHandler} className="colupsMenuSidebar">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fal"
          data-icon="bars"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          className="svg-inline--fa fa-bars fa-w-14 fa-fw"
        >
          <path
            fill="currentColor"
            d="M442 114H6a6 6 0 0 1-6-6V84a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6z"
          ></path>
        </svg>
      </div>
      <div className="sidebarWrap">
        <div className="logo">
          <NavLink to="/dashboard">
            {/* <img className="normal" src={logo} alt="" />
            <img className="colupsLogo" src={smallLogo} alt="" /> */}
            <h1 style={{color:"white"}}>SY Factory</h1>
          </NavLink>
        </div>
        <Grid
          className="sidebarMenu"
          style={{ background: `url(${sidenavBg}) center/cover repeat` }}
        >
          <PerfectScrollbar>
            {navigations.map((nav) => (
              <Fragment key={nav.id}>
                <ExpansionPanel
                  classes={{
                    root: "navItems",
                    expanded: "navItemsExpanded",
                  }}
                  square
                  expanded={
                    parent_expanded === nav.id || nav.alwaysexpand === true
                  }
                  onChange={parent_handleChange(nav.id)}
                >
                  <ul className="submenu">
                    {nav.menus.map((menu, i) => (
                      <li key={menu.id}>
                        {menu.link ? (
                          <NavLink
                            className="navItem"
                            activeClassName="active"
                            exact
                            onClick={()=>{window.scrollTo(0, 0)
                              window.innerWidth < 977 && props.colupsMenuHandler()
                            }}
                            to={menu.link}
                          >
                            <span className="name">{menu.icon} {menu.name}</span>
                            {menu.value && (
                              <span
                                style={{ background: menu.color }}
                                className="value"
                              >
                                {menu.value}
                              </span>
                            )}
                          </NavLink>
                        ) : (
                          <ExpansionPanel
                            classes={{
                              root: "navItems",
                              expanded: "navItemsExpanded",
                            }}
                            square
                            expanded={expanded === menu.id}
                            onChange={handleChange(menu.id)}
                          >
                            <ExpansionPanelSummary
                              classes={{
                                root: "navItemsText",
                                expanded: "navItemsTextExpanded",
                                expandIcon: "navItemsTextIcon",
                                content: "navItemsTextContent",
                              }}
                              expandIcon={<i className="fa fa-angle-down" />}
                            >
                              <span className="name">{menu.name}</span>
                            </ExpansionPanelSummary>
                            <ul className="thirdmenuItems">
                              {menu.submenus.map((submenu, i) => (
                                <li key={i}>
                                  <NavLink
                                    onClick={window.scrollTo(0, 0)}
                                    activeClassName="active"
                                    exact
                                    to={submenu.link}
                                  >
                                    {submenu.name}
                                  </NavLink>
                                </li>
                              ))}
                            </ul>
                          </ExpansionPanel>
                        )}
                      </li>
                    ))}
                  </ul>
                </ExpansionPanel>
              </Fragment>
            ))}
          </PerfectScrollbar>
        </Grid>
      </div>
    </Grid>
  );
};

export default SidebarNav;
